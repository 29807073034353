.circle-iconic-box {
	> .box-item {
		position: absolute;
		padding-left: 0;
		width: 200px;

		> .icon {
			display: none;
		}

	}

	&.left-side {
		> .box-item:nth-of-type(1) {
			top: -20px;
			left: 45px;
		}

		> .box-item:nth-of-type(2) {
			top: 235px;
			left: 0px;
		}

		> .box-item:nth-of-type(3) {
			top: 485px;
			left: 110px;
		}
	}

	&.right-side {
		> .box-item:nth-of-type(1) {
			top: -65px;
			left: 50px;
		}

		> .box-item:nth-of-type(2) {
			top: 145px;
			left: 120px;
		}

		> .box-item:nth-of-type(3) {
			top: 320px;
			left: 65px;
		}

		> .box-item:nth-of-type(4) {
			top: 575px;
			left: -35px;
		}
	}
}

.features-circle {
	position: relative;

	.middle-circle {
		width: 500px;
		height: 500px;
		border-radius: 50%;
		border: 2px solid #000;
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		> img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		> .icon {
			position: absolute;
			width: 70px;
			height: 70px;
			border-radius: 50%;
			border: 1px solid #000;
			background: #f3f2f2;

			> span {
				position: absolute;
			}

			> i {
				font-size: 22px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}

			&.size-xxl {
				width: 210px;
				height: 210px;

				> i {
					font-size: 40px;
				}
			}

			&.size-m {
				width: 120px;
				height: 120px;

				> i {
					font-size: 24px;
				}
			}

			&.size-l {
				width: 140px;
				height: 140px;

				> i {
					font-size: 36px;
				}
			}

			&.size-xl {
				width: 170px;
				height: 170px;

				> i {
					font-size: 38px;
				}
			}

			&:nth-of-type(1) {
				top: -52px;
				left: 27px;

				> span {
					width: 65px;
					height: 11px;
					background-image: url(../img/d-left-1.png);
					background-repeat: no-repeat;
					top: 70px;
					left: -100px;
				}
			}

			&:nth-of-type(2) {
				top: 219px;
				left: -58px;

				> span {
					width: 35px;
					height: 11px;
					background-image: url(../img/d-left-2.png);
					background-repeat: no-repeat;
					top: 65px;
					left: -75px;
				}
			}

			&:nth-of-type(3) {
				top: 395px;
				left: 54px;

				> span {
					width: 56px;
					height: 28px;
					background-image: url(../img/d-left-3.png);
					background-repeat: no-repeat;
					top: 85px;
					left: -75px;
				}
			}

			&:nth-of-type(4) {
				top: 0px;
				right: 96px;

				> span {
					width: 146px;
					height: 33px;
					background-image: url(../img/d-right-1.png);
					background-repeat: no-repeat;
					top: -35px;
					left: 85px;
				}
			}
			&:nth-of-type(5) {
				top: 102px;
				right: -68px;

				> span {
					width: 44px;
					height: 11px;
					background-image: url(../img/d-right-2.png);
					background-repeat: no-repeat;
					top: 45px;
					left: 160px;
				}
			}
			&:nth-of-type(6) {
				top: 323px;
				right: -5px;

				> span {
					width: 44px;
					height: 11px;
					background-image: url(../img/d-right-3.png);
					background-repeat: no-repeat;
					top: 35px;
					left: 100px;
				}
			}
			&:nth-of-type(7) {
				top: 392px;
				right: 115px;

				> span {
					width: 86px;
					height: 28px;
					background-image: url(../img/d-right-4.png);
					background-repeat: no-repeat;
					top: 170px;
					left: 165px;
				}
			}
		}
	}
}