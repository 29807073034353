.pricing-table {
	background-color: #FFF;
	text-align: center;
	padding-top: 120px;
	padding-bottom: 70px;
	position: relative;
	transition: all 0.15s ease-in-out;

	
	> .pricing-header {
		margin-bottom: 50px;

		> .name {
			font-size: 0.857em;
			letter-spacing: 0.10em;
			text-transform: uppercase;
			font-weight: 700;
			margin: 0;
			margin-bottom: 25px;
		}

		> .price {
			font-size: 5em;
			line-height: 0.8em;
			font-weight: 700;

			small {
				font-size: 0.485em;
			}
		}

		> .cycle {
			font-size: 0.857em;
			letter-spacing: 0.10em;
			text-transform: uppercase;
			font-weight: 700;
		}
	}

	> .pricing-features {
		margin: 0;
		padding: 0;
		font-family: 'Open Sans', sans-serif;
		list-style: none;
		line-height: 2.142em;
		letter-spacing: 0.050em;
		margin-bottom: 65px;
	}

	.buy-link {
		position: relative;
		display: inline-block;
		font-size: 0.857em;
		font-weight: 400;
		text-transform: uppercase;
		color: #000;
		z-index: 1;


		&:after {
			content: '';
			border-style: solid;
			border-width: 50px 50px 0 50px;
			border-color: transparent;
			border-top-color: #f2f2f2;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -30%);
			z-index: -1;
		}

		&:hover {
			color: #FFF;
			&:after {
				border-top-color: #ea2e2b;
			}
		}
	}

	&.active {
		transform: scale(1.05,1.05);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
		z-index: 5;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 10px;
			top: 0;
			left: 0;
			background-color: #ea2e2b;
		}
	}
}