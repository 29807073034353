.team-peoples-carousel {
	position: relative;
	overflow: hidden;

	.team-peoples {
		padding: 25px 0;

		.people {
			position: relative;
			
			> .people-img {
				margin-bottom: 40px;
				opacity: .25;
				transition: all .2s ease-in-out;
				position: relative;
				z-index: 2;

				> img {
					width: 100%;
					height: auto;
				}
			}

			> .people-profile {
				position: relative;
				text-align: center;
				transition: all .5s ease-in-out;
				transform: translateY(-150%);
				opacity: 0;
				z-index: 1;

				> .name-job {
					position: relative;
					overflow: hidden;
					margin-bottom: 25px;

					> .name {
						font-size: 1.285em;
						font-weight: 400;
						color: #ea2e2b;
						text-transform: uppercase;
						letter-spacing: 0.10em;

						&:before {
							content: attr(data-name);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 2.5em;
							line-height: 1em;;
							color: #000;
							text-align: center;
							width: 130%;
							opacity: 0.03;
						}
					}
				}
			}

			&.swiper-slide-active {
				z-index: 5;

				> .people-img {
					opacity: 1;
					transform: scale(1.05);
		  			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
				}

				> .people-profile {
					transform: translateY(0%);
					opacity: 1;
				}
			}
		}
	}

	> [data-swiper="next"],
	> [data-swiper="prev"] {
		position: absolute;
		top: 50%;
		transform: translateY(-120%);
		font-size: 45px;
		z-index: 25;
		color: #ea2e2b;
		cursor: pointer;
		opacity: 0;
		transition: all 0.2s ease-in-out;
	}
	[data-swiper="prev"] {
		left: 0;
	}
	[data-swiper="next"] {
		right: 0;
	}

	&:hover {
		[data-swiper="prev"] {
			left: 60px;
			opacity: 1;
		}
		[data-swiper="next"] {
			right: 60px;
			opacity: 1;
		}
	}
}