.gmap3-section {
	height: 150px;
	overflow: hidden;
	filter: grayscale(100%);
	transition: all 0.5s ease-in-out;

	&.full {
		filter: grayscale(0%);
		height: 500px;
	}
	.gmap3-area {
		height: 500px;
	}
}
[data-toogle="map"] {
	&:hover {
		cursor: pointer;
	}
}