/*--------------------------- */
/* [Table of contents] */

/* 1. Global Rules */
/* 2. Decent Icons */
/* 3. Elements */
/* 3.1 Side Menu */
/* 3.2 Social Icons */
/* 3.3 Triangle Button */
/* 3.4 Heading */
/* 3.5 Iconic Box */
/* 3.6 Box Items */
/* 3.7 Featured Circle */
/* 3.8 Service Box */
/* 3.9 Pricing Table */
/* 3.10 Action Area */
/* 3.11 Special Testimonials */
/* 3.12 Buttons */
/* 3.13 Page Navigation */
/* 3.14 Skills Bars */
/* 3.15 Post Share */
/* 3.16 Comment Form */
/* 3.17 Blockquote */
/* 3.18 Back to top */
/* 4. Widgets */
/* 4.1 Search */
/* 4.2 Popular Posts */
/* 4.3 Categories */
/* 4.4 Newsletter */
/* 4.5 Tags */
/* 4.6 Archives */
/* 5. Sections */
/* 5.1 Header */
/* 5.2 Banner */
/* 5.3 About */
/* 5.4 Why Choose Us */
/* 5.5 Site Stats */
/* 5.6 Team */
/* 5.7 Portfolio */
/* 5.8 Process Flow */
/* 5.9 Client Says */
/* 5.10 Awesome clients */
/* 5.11 Special */
/* 5.12 Contact */
/* 5.13 Map */
/* 5.12 Footer */
/* 6. Blog */
/* 6.1 Banner Static */
/* 6.2 Blog */
/* 6.3 Blog Single */
/* 6.4 Comments */
/* 7. Layout */
/* 7.1 Dark Layout */
/* 7.2 Responsive */
/*--------------------------- */

/*==================================== */
/* =         1. Global Rules         = */
/* ====================================*/
@import 'base/colors';
@import 'base/mixins';
@import 'base/bootstrap';

/*==================================== */
/* =          2. Decent Icons         = */
/* ====================================*/
@import 'base/decent-icons';

/*==================================== */
/* =           3. Elements           = */
/* ====================================*/

/* 3.1 Side Menu */
@import 'elements/slide-menu';
/* 3.2 Social Icons */
@import 'elements/social-icon';
/* 3.3 Triangle Button */
@import 'elements/triangle';
/* 3.4 Heading */
@import 'elements/heading';
/* 3.5 Iconic Box */
@import 'elements/iconic-box';
/* 3.6 Box Items */
@import 'elements/box-items';
/* 3.7 Featured Circle */
@import 'elements/features-circle';
/* 3.8 Service Box */
@import 'elements/service-box';
/* 3.9 Pricing Table */
@import 'elements/pricing-table';
/* 3.10 Action Area */
@import 'elements/mock-up-action';
/* 3.11 Special Testimonials */
@import 'elements/special-testimonials';
/* 3.12 Buttons */
@import 'elements/buttons';
/* 3.13 Page Navigation */
@import 'elements/page-navigation';
/* 3.14 Skills Bars */
@import 'elements/skills-bars';
/* 3.15 Post Share */
@import 'elements/post-share';
/* 3.16 Comment Form */
@import 'elements/comment-form';
/* 3.17 Blockquote */
@import 'elements/blockquote';
/* 3.18 Back to top */
@import 'elements/back-top';

/*==================================== */
/* =            4. Widgets           = */
/* ====================================*/
@import 'widgets/widgets';
/* 4.1 Search */
@import 'widgets/search';
/* 4.2 Popular Posts */
@import 'widgets/popular-posts';
/* 4.3 Categories */
@import 'widgets/categories';
/* 4.4 Newsletter */
@import 'widgets/newsletter';
/* 4.5 Tags */
@import 'widgets/tags';
/* 4.6 Archives */
@import 'widgets/archives';

/*==================================== */
/* =            5. Sections          = */
/* ====================================*/
/* 5.1 Header */
@import 'base/header';
/* 5.2 Banner */
@import 'sections/banner';
/* 5.3 About */
@import 'sections/about';
/* 5.4 Why Choose Us */
@import 'sections/why-choose-us';
/* 5.5 Site Stats */
@import 'sections/site-stats';
/* 5.6 Team */
@import 'sections/team';
/* 5.7 Portfolio */
@import 'sections/portfolio';
/* 5.8 Process Flow */
@import 'sections/process-flow';
/* 5.9 Client Says */
@import 'sections/clients-says';
/* 5.10 Awesome clients */
@import 'sections/awesome-clients';
/* 5.11 Special */
@import 'sections/special';
/* 5.12 Contact */
@import 'sections/contact';
/* 5.13 Map */
@import 'sections/map';
/* 5.12 Footer */
@import 'sections/footer';


/*==================================== */
/* =            6. Blog              = */
/* ====================================*/
/* 6.1 Banner Static */
@import 'sections/banner-static';
/* 6.2 Blog */
@import 'sections/blog';
/* 6.3 Blog Single */
@import 'sections/blog-single';
/* 6.4 Comments */
@import 'sections/comments';

/*==================================== */
/* =            7. Layout            = */
/* ====================================*/
/* 7.1 Dark Layout */
@import 'base/dark-layout';
/* 7.2 Responsive */
@import 'base/responsive';


/*==================================== */
/* =            8. Extras            = */
/* ====================================*/
@import 'extras/color-picker';
