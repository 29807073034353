.box-item {
	position: relative;
	padding-left: 60px;
	margin-bottom: 35px;

	> .icon {
		position: absolute;
		width: 40px;
		left: 0;
		top: 0;

		> i {
			font-size: 40px;
		}
	}

	.title {
		font-size: 1.285em;
		margin-top: 0;
		margin-bottom: 13px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.050em;
	}

	.content {
		font-size: 1em;
		line-height: 1.714em;
		font-family: 'Open Sans', sans-serif;
	}
}