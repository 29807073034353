blockquote {
	border-left-color: #ea2e2b;
	font-size: 1.142em;
	font-weight: 400;

	> .info {
		font-size: 0.875em;
		font-weight: 300;
		padding-top: 15px;

		> span {
			color: #999999;
		}
	}
}