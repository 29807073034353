#back-to-top {
	position: fixed;
	right: 40px;
	bottom: 40px;
	z-index: 1000;
	width: 70px;
	height: 70px;
	color: #FFF;
	opacity: 0;
	transition: all 0.30s ease-in-out;

	&.show {
		opacity: 1;
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 70px 70px;
		border-color: transparent transparent #ea2e2b transparent;
		z-index: 1;
		transition: all 0.15s ease-in-out;
	}

	> span {
		position: absolute;
		z-index: 2;
		bottom: 12px;
		right: 6px;
		font-size: 0.857em;
		font-weight: 400;
		text-transform: uppercase;
		transform: rotate(-40deg);
	}

	&:hover {
		cursor: pointer;

		&:before {
			border-color: transparent transparent darken(#ea2e2b, 20%) transparent;
		}
	}
}