$light-grey: lightgrey;
$dark-grey: #555;
$red: #ea2e2b;
$white: #FFF;


// Theme Color
$blue: #428bca;
$green: #88c136;
$red: #e54242;
$yellow: #ff9c00;
$brown: #987654;
$cyan: #1ABC9C;
$purple: #c74a73;
$sky-blue: #00cdcd;