.process-flow-nav {
	margin: 0;
	padding: 0;
	text-align: center;
	margin-bottom: 85px;

	> li {
		list-style: none;
		display: inline-block;
		margin: 0 40px;

		> a {
			display: inline-block;
			color: inherit;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}

			> i {
				display: block;
				width: 145px;
				height: 145px;
				font-size: 2.571em;
				border: 2px solid #DDD;
				border-radius: 50px;
				transition: all 0.15s ease-in-out;
				transform: rotate(-45deg);
				margin-bottom: 35px;
				position: relative;

				&:before {
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
				}

				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					width: 25px;
					height: 1px;
					background-color: #FFF;
					transform: rotate(45deg);
					right: -20px;
					bottom: -10px;
				}

				&:hover {
					background-color: #ea2e2b;
					background-image: url(../img/iconalt.svg);
					background-position: center center;
					background-size: 100% 100%;
					color: #FFF;
				}
			}

			> span {
				display: block;
				font-size: 1.071em;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.10em;

			}
		}

		&.active > a > i {
			background-color: #ea2e2b;
			background-image: url(../img/iconalt.svg);
			background-position: center center;
			background-size: 100% 100%;
			color: #FFF;
		}

		&:last-child > a > i:after {
			display: none;
		}
	}
}

.process-flow-content {
	position: relative;
	margin-bottom: 50px;

	.flow-title {
		display: inline-block;
		font-size: 1.285em;
		font-weight: 700;
		letter-spacing: 0.10em;
		text-transform: uppercase;
		margin-top: 0;
		border-bottom: 2px solid #ea2e2b;
		padding-bottom: 7px;
		margin-bottom: 25px;
	}

	.flow-content {
		font-size: 1.142em;
		line-height: 1.5em;
	}
}