.static-banner {
	position: relative;
	margin-bottom: 20px;

	.banner-content {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

		> h1 {
			font-size: 5.714em;
			letter-spacing: 0.020em;
			font-weight: 100;
			margin: 0;
			margin-bottom: 40px;

			&.size2 {
				font-size: 4em;
			}

			> span {
				font-weight: 400;
				color: #ea2e2b;
			}

			&.huge {
				> span {
					font-weight: 700;
				}
			}
		}

		> p {
			font-size: 1.142em;
			letter-spacing: 0.020em;
			line-height: 1.5em;
		}

		.date {
			font-size: 1.428em;
			letter-spacing: 0.050em;
			color: #ea2e2b;
			text-decoration: underline;
		}
	}
}