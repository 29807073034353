.dti-pager {
	padding: 40px 0;
	text-align: center;
	
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}

	> li {
		display: inline;

		&.previous {
			float: left;
			a:hover i {
				transform: translateX(-10px);
			}
		}
		&.next {
			float: right;

			a:hover i {
				transform: translateX(10px);
			}
		}

		> a {
			display: block;
			border: 1px solid #ea2e2b;
			color: #ea2e2b;
			font-size: 1.071em;
			letter-spacing: 0.050em;
			font-weight: 400;
			padding: 10px 25px;

			// &:hover {
			// 	background-color: #ea2e2b;
			// 	color: #fff;
			// }

			> i {
				display: inline-block;
				height: 16px;
				overflow: hidden;
				position: relative;
				padding: 0 5px;
				transition: all 0.15s;

				&:before {
					font-size: 32px;
					line-height: 0.7em;
				}
			}
		}
	}
}