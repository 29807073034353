.about-us-content {
	margin-bottom: 50px;

	> .title {
		margin: 0;
		margin-bottom: 55px;
		font-size: 2.142em;
		font-weight: 700;

		> span {
			font-weight: 300;
		}
	}

	> .info-content {
		font-size: 1.142em;
		letter-spacing: 0.02em;
		line-height: 1.6em;
	}
}

.skill-items {
	> .item {
		margin-bottom: 65px;

		> .name {
			font-size: 1.142em;
			letter-spacing: 0.020em;
		}
	}
}

.skills-title {
	font-size: 1.428em;
	letter-spacing: 0.060em;
	font-weight: 700;
}