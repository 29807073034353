.single-post {
	
	article.post{
		.entry-inside {
			> .entry-meta {
				margin-bottom: 30px;
			}

			.entry-content {

				p {
					margin-bottom: 65px;
				}
			}
		}
	}

	.post-share {
		margin-bottom: 50px;
	}

	.dti-pager {
	    border-bottom: 1px dashed #c7c7c7;
	    padding-bottom: 60px;
	    margin-bottom: 100px;
	}

	.comments-area {
		margin-bottom: 100px;
	}
}