.clients-logo {

	.logo-item {
		height: 310px;
		position: relative;

		> a {
			position: absolute;
			width: 100%;
			height: 100%;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;


			> img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0.30;
				transition: all 0.15s ease-in-out;
			}
			&:hover {
				> img {
					opacity: 1;
				}
			}
		}
	}

	div:nth-child(3)  {
		.logo-item > a {
			border-right: none;
		}
	}
	div:nth-child(6)  {
		.logo-item > a {
			border-right: none;
		}
	}
	div:nth-child(9)  {
		.logo-item > a {
			border-right: none;
		}
	}
	div:nth-of-type(n+7)  {
		.logo-item > a {
			border-bottom: none;
		}
	}
}