.stats-item {
	position: relative;
	text-align: center;
	margin-bottom: 50px;

	.glass-icon {
		margin-bottom: 25px;
	}

	> i {
		font-size: 5em;
		margin-bottom: 25px;
	}

	> .stat-info {
		position: relative;
		font-size: 2.571em;
		font-weight: 400;

		> .back-amount {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 90px;
			opacity: 0.03;
			z-index: 1;
		}

		> .amount {
			position: relative;
			z-index: 2;
			letter-spacing: 0.060em;
		}

		> .type {
			text-transform: uppercase;
			font-size: .335em;
			color: #ea2e2b;
		}
	}
}