.widget {
	margin-bottom: 100px;

	> .widget-title {
		font-size: 1.142em;
		line-height: 1.25em;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 45px;
		text-transform: uppercase;
	}
}