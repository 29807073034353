.dt-progress {
    background-color: #c8c8c8;
    height: 10px;
    position: relative;
    margin-bottom: 25px;

    > span {
        position: absolute;
        top: 50%;
        left: 5px;
        // width: 90%;
        height: 2px;
        background-color: #ea2e2b;
        transform: translateY(-50%);

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            height: 0px;
            width: 0px;
            border-style: solid;
            border-width: 4px 0 4px 4px;
            border-color: transparent transparent transparent #ea2e2b;
            transform: translateY(-50%);
        }

        &:after {
            content: attr(data-amount);
            position: absolute;
            top: 6px;
            right: 12px;
            padding: 2px 10px;
            background-color: #ea2e2b;
            color: #FFF;
            transform: skew(-30deg);
            font-size: 1.1em;
            font-weight: 400;
        }
    }

}