.triangle-area {
	position: relative;
}
.triangle {
	position: absolute;
	left: 50%;
	bottom: 0px;
	transform: translate(-50%, -10px);
	z-index: 20;
	height: 100px;
	width: 220px;

	&:before {
		content: "";
		position: absolute;
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 0 110px 110px 110px;
		border-color: transparent;
		border-bottom-color: #ea2e2b;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.15s ease-in-out;
	}
	&:after {
		content: "";
		position: absolute;
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 50px 50px 0 50px;
		border-color: transparent;
		border-top-color: #FFF;
		left: 50%;
		transform: translateX(-50%);
		top: 20px;
		transition: all 0.15s ease-in-out;
		

	}
	> span {
		display: inline-block;
		color: #000000;
		position: absolute;
		font-size: 0.857em;
		font-weight: 400;
		text-transform: uppercase;
		left: 50%;
		transform: translateX(-50%);
		top: 25px;
		z-index: 5;
		transition: all 0.15s ease-in-out;

		// &:before {
		// 	content: "";
		// 	display: inline-block;
		// 	border-right: 3px dashed #cccccc;
		// 	height: 50px;
		// 	position: absolute;
		// 	left: 50%;
		// 	margin-left: -0.15px;
		// 	transform: translateX(-50%);
		// 	top: 50px;
		// }
	}
	// &:hover:before {
	// 	border-color: transparent transparent #f2f2f2 transparent;
	// }
	// &:hover:after {
	// 	top: 35px;
	// 	border-color: #ea2e2b transparent transparent transparent;
	// }
	// &:hover > span {
	// 	top: 40px;
	// 	color: #ffffff;
	// }
}