.post-share {
	padding-left: 0;

	> li {
		display: inline-block;

		&.share-title {
			margin-right: 15px;
			transform: translateY(-15px);
		}

		&.item {
			margin: 0 5px;
			> a {
				display: block;
				width: 38px;
				height: 38px;
				color: #cccccc;
				border: 1px solid #cccccc;
				border-radius: 50%;
				position: relative;

				&:hover {
					color: #ea2e2b;
					border-color: #ea2e2b;
				}

				> i {
					font-size: 1.142em;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}