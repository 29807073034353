.special-vertical-carousel {

	.swiper-container {
		height: 100px;
		overflow: hidden;

		.swiper-slide {
			> h3 {
				font-size: 0.928em;
				font-weight: 700;
				text-transform: uppercase;
				margin-top: 0;
				margin-bottom: 20px;
				text-align: center;
			}
			> h2 {
				font-size: 2.142em;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
				margin: 0;
			}
		}
		
		.swiper-pagination {
			> span {
				height: 20px;
				background-color: #6b6b6b;
				border-radius: 25px;
				opacity: 1;
				width: 4px;
				margin: 10px 0;

				&.swiper-pagination-bullet-active {
					background-color: #ea2e2b;
				}
			}
		}
	}
	
}