body {
  @include font-smoothing; // <- _mixins.scss
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  letter-spacing: 0.020em;
  line-height: 24px;
  font-weight: 300;
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
}
#page-outer {
  overflow-x: hidden;

  &.animated-version {
    > section {
      position: relative;
      overflow: hidden;
    }
  }
}

[data-spy="scroll"] {
  position: relative;
}
a {
	transition: all 0.15s ease-in-out;
	
	&:hover,
  &:focus {
    outline-color: transparent;
		text-decoration: none;
	}
}
// Section Padding
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

// Transparent Section
.transparent-section,
.transparent {
  color: #FFF;
}

// Special Text
.special-text {
  font-size: 1.428em;
  font-weight: 700;
  letter-spacing: 0.10em;

  span {
    color: #ea2e2b;
  }
}

// Swiper Carousel Nav
[data-swiper="next"],
[data-swiper="prev"] {
  &:hover {
    cursor: pointer;
  }
}

// Colors
.alt-color {
  color: #ea2e2b;
}
.highlited {
  background-color: #ea2e2b;
  color: #FFF;
}

// Image background
[data-bg-image] {
  background-size: cover;
}

// Full Screen
.full-screen {
  height: 100vh;

  > .overlay {
    height: 100vh;
  }
}

// Video Background
.background-video-section {
  position: relative;
  
  > .overlay {
    position: relative;
    z-index: 2;
  }
  > .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

// Animation Fix
.swiper-slide [data-animate] {
  opacity: 0;
  transition: opacity 0.2s;
}
.swiper-slide.swiper-slide-active [data-animate] {
  opacity: 1;
  transition-delay: 0.5s;
}

// Footer Blank Space
.footer-blank {
  position: fixed;
  height: 40px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
  border-top: 1px solid #F1F1F1;
  z-index: 20;
}

input,
textarea {
  border: 1px solid #e4e4e4;
  background-color: #FFF;
  padding: 10px 20px;
  transition: all 0.15s ease-in-out;

  &:focus {
    border-color: #ea2e2b;
  }
}