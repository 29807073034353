.clients-says-carousel {
	.clients-says-items {
		margin-bottom: 90px;

		> .item {
			> .info {
				margin-bottom: 50px;

				> .photo {
					width: 180px;
					height: 180px;
					border-radius: 60px;
					overflow: hidden;
					transform: rotate(45deg);
					margin: 15px auto;
					margin-bottom: 35px;
					position: relative;

					> img {
						width: 100%;
						height: auto;
						transform: rotate(-45deg) scale(1.2);
					}
				}

				> .name-job {
					text-align: center;

					> .name {
						font-size: 1.285em;
						margin: 0;
						margin-bottom: 10px;
						font-weight: 400;
						text-transform: uppercase;
					}
					> .job {
						font-style: italic;
					}
				}
			}

			> .content {
				text-align: center;
				font-size: 1.142em;
			}
		}
	}
	
	// Controls
	.controls {
		position: relative;
		text-align: center;

		[data-swiper="next"],
		[data-swiper="prev"] {
			display: inline-block;
			position: relative;
			width: 32px;
			height: 32px;
			background-color: #FFF;
			border-radius: 10px;
			color: #000;
			transform: rotate(45deg);
			margin: 0 10px;
			transition: all 0.15s ease-in-out;

			> i {
				font-size: 1.5em;
				text-align: center;
				// transform: rotate(-45deg);
				line-height: 1.8em;
			}

			&:hover {
				background-color: #ea2e2b;
				color: #FFF;
			}
		}
	}
}