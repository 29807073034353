.social-icons {
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;

		> a {
			color: #000;

			&:hover {
				color: #ea2e2b;
			}
		}
	}

	&.rounded {
		> li > a {
			display: block;
			background: #000000;
			color: #333333;
			border: 1px solid #333333;
			border-radius: 50%;
			width: 32px;
			height: 32px;
			position: relative;

			> i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}

			&:hover {
				border-color: #ea2e2b;
				background-color: #ea2e2b;
				color: #FFF;
			}
		}

		&.rounded {
			> li > a {
				width: 42px;
				height: 42px;
				font-size: 1.142em;
			}
		}
	}
}