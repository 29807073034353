@charset "UTF-8";

@font-face {
  font-family: "dt-icons";
  src:url("../fonts/dt-icons.eot");
  src:url("../fonts/dt-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dt-icons.woff") format("woff"),
    url("../fonts/dt-icons.ttf") format("truetype"),
    url("../fonts/dt-icons.svg#dt-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dt-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dti-"]:before,
[class*=" dti-"]:before {
  font-family: "dt-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dti-load-anticlockwise-dashed:before {
  content: "\e000";
}
.dti-move-bottom:before {
  content: "\e001";
}
.dti-button-down:before {
  content: "\e002";
}
.dti-button-off:before {
  content: "\e003";
}
.dti-button-on:before {
  content: "\e004";
}
.dti-button-up:before {
  content: "\e005";
}
.dti-check:before {
  content: "\e006";
}
.dti-circle-check:before {
  content: "\e007";
}
.dti-circle-angle-down:before {
  content: "\e008";
}
.dti-circle-angle-down-left:before {
  content: "\e009";
}
.dti-circle-angle-down-right:before {
  content: "\e00a";
}
.dti-circle-angle-right:before {
  content: "\e00b";
}
.dti-circle-minus:before {
  content: "\e00c";
}
.dti-circle-plus:before {
  content: "\e00d";
}
.dti-circle-remove:before {
  content: "\e00e";
}
.dti-circle-left:before {
  content: "\e00f";
}
.dti-circle-up:before {
  content: "\e010";
}
.dti-circle-up-left:before {
  content: "\e011";
}
.dti-circle-angle-up-right:before {
  content: "\e012";
}
.dti-load-clockwise:before {
  content: "\e013";
}
.dti-load-clockwise-dashed:before {
  content: "\e014";
}
.dti-compress:before {
  content: "\e015";
}
.dti-deny:before {
  content: "\e016";
}
.dti-diagonal:before {
  content: "\e017";
}
.dti-diagonal2:before {
  content: "\e018";
}
.dti-angle-down:before {
  content: "\e019";
}
.dti-angle-down-double:before {
  content: "\e01a";
}
.dti-angle-down-left:before {
  content: "\e01b";
}
.dti-angle-down-right:before {
  content: "\e01c";
}
.dti-drag-down:before {
  content: "\e01d";
}
.dti-drag-down-dashed:before {
  content: "\e01e";
}
.dti-drag-horiz:before {
  content: "\e01f";
}
.dti-drag-left:before {
  content: "\e020";
}
.dti-drag-left-dashed:before {
  content: "\e021";
}
.dti-drag-right:before {
  content: "\e022";
}
.dti-drag-right-dashed:before {
  content: "\e023";
}
.dti-drag-up:before {
  content: "\e024";
}
.dti-drag-up-dashed:before {
  content: "\e025";
}
.dti-drag-vert:before {
  content: "\e026";
}
.dti-circle-exclamation:before {
  content: "\e027";
}
.dti-expand:before {
  content: "\e028";
}
.dti-expand-diagonal:before {
  content: "\e029";
}
.dti-expand-horizontal:before {
  content: "\e02a";
}
.dti-expand-vertical:before {
  content: "\e02b";
}
.dti-fit-horizontal:before {
  content: "\e02c";
}
.dti-fit-vertical:before {
  content: "\e02d";
}
.dti-glide:before {
  content: "\e02e";
}
.dti-glide-horizontal:before {
  content: "\e02f";
}
.dti-glide-vertical:before {
  content: "\e030";
}
.dti-hamburger:before {
  content: "\e031";
}
.dti-hamburger2:before {
  content: "\e032";
}
.dti-horizontal:before {
  content: "\e033";
}
.dti-circle-info:before {
  content: "\e034";
}
.dti-square-keyboard-alt:before {
  content: "\e035";
}
.dti-square-keyboard-cmd:before {
  content: "\e036";
}
.dti-square-keyboard-delete:before {
  content: "\e037";
}
.dti-square-keyboard-down:before {
  content: "\e038";
}
.dti-square-keyboard-left:before {
  content: "\e039";
}
.dti-square-keyboard-return:before {
  content: "\e03a";
}
.dti-square-keyboard-right:before {
  content: "\e03b";
}
.dti-square-keyboard-shift:before {
  content: "\e03c";
}
.dti-square-keyboard-tab:before {
  content: "\e03d";
}
.dti-square-keyboard-up:before {
  content: "\e03e";
}
.dti-angle-left:before {
  content: "\e03f";
}
.dti-angle-left-double:before {
  content: "\e040";
}
.dti-minus:before {
  content: "\e041";
}
.dti-move:before {
  content: "\e042";
}
.dti-move2:before {
  content: "\e043";
}
.dti-load-anticlockwise:before {
  content: "\e044";
}
.dti-move-left:before {
  content: "\e045";
}
.dti-move-right:before {
  content: "\e046";
}
.dti-move-top:before {
  content: "\e047";
}
.dti-plus:before {
  content: "\e048";
}
.dti-circle-question:before {
  content: "\e049";
}
.dti-remove:before {
  content: "\e04a";
}
.dti-angle-right:before {
  content: "\e04b";
}
.dti-angle-right-double:before {
  content: "\e04c";
}
.dti-rotate:before {
  content: "\e04d";
}
.dti-rotate-anti:before {
  content: "\e04e";
}
.dti-rotate-anti-dashed:before {
  content: "\e04f";
}
.dti-rotate-dashed:before {
  content: "\e050";
}
.dti-shrink:before {
  content: "\e051";
}
.dti-shrink-diagonal:before {
  content: "\e052";
}
.dti-shrink-diagonal2:before {
  content: "\e053";
}
.dti-shrink-horizonal:before {
  content: "\e054";
}
.dti-shrink-horizonal2:before {
  content: "\e055";
}
.dti-shrink-vertical2:before {
  content: "\e056";
}
.dti-shrink-vertical:before {
  content: "\e057";
}
.dti-sign-down:before {
  content: "\e058";
}
.dti-sign-left:before {
  content: "\e059";
}
.dti-sign-right:before {
  content: "\e05a";
}
.dti-sign-up:before {
  content: "\e05b";
}
.dti-slide-down:before {
  content: "\e05c";
}
.dti-slide-down2:before {
  content: "\e05d";
}
.dti-slide-left:before {
  content: "\e05e";
}
.dti-slide-left2:before {
  content: "\e05f";
}
.dti-slide-right:before {
  content: "\e060";
}
.dti-slide-right2:before {
  content: "\e061";
}
.dti-slide-top:before {
  content: "\e062";
}
.dti-slide-top2:before {
  content: "\e063";
}
.dti-slim-down:before {
  content: "\e064";
}
.dti-slim-down-dashed:before {
  content: "\e065";
}
.dti-slim-left:before {
  content: "\e066";
}
.dti-slim-left-dashed:before {
  content: "\e067";
}
.dti-slim-right:before {
  content: "\e068";
}
.dti-slim-right-dashed:before {
  content: "\e069";
}
.dti-slim-top:before {
  content: "\e06a";
}
.dti-slim-top-dashed:before {
  content: "\e06b";
}
.dti-square-check:before {
  content: "\e06c";
}
.dti-square-angle-down:before {
  content: "\e06d";
}
.dti-square-angle-down-left:before {
  content: "\e06e";
}
.dti-square-angle-down-right:before {
  content: "\e06f";
}
.dti-square-angle-left:before {
  content: "\e070";
}
.dti-square-minus:before {
  content: "\e071";
}
.dti-square-plus:before {
  content: "\e072";
}
.dti-square-remove:before {
  content: "\e073";
}
.dti-square-angle-right:before {
  content: "\e074";
}
.dti-square-angle-top:before {
  content: "\e075";
}
.dti-square-angle-top-left:before {
  content: "\e076";
}
.dti-square-angle-top-right:before {
  content: "\e077";
}
.dti-squares:before {
  content: "\e078";
}
.dti-stretch-diagonal:before {
  content: "\e079";
}
.dti-stretch-diagonal2:before {
  content: "\e07a";
}
.dti-stretch-diagonal3:before {
  content: "\e07b";
}
.dti-stretch-diagonal4:before {
  content: "\e07c";
}
.dti-stretch-horizontal:before {
  content: "\e07d";
}
.dti-stretch-horizontal2:before {
  content: "\e07e";
}
.dti-stretch-vertical:before {
  content: "\e07f";
}
.dti-stretch-vertical2:before {
  content: "\e080";
}
.dti-switch-horizontal:before {
  content: "\e081";
}
.dti-switch-vertical:before {
  content: "\e082";
}
.dti-angle-up:before {
  content: "\e083";
}
.dti-angle-up-double:before {
  content: "\e084";
}
.dti-angle-up-left:before {
  content: "\e085";
}
.dti-angle-up-right:before {
  content: "\e086";
}
.dti-vertical:before {
  content: "\e087";
}
.dti-alarm:before {
  content: "\e088";
}
.dti-envelope:before {
  content: "\e089";
}
.dti-anchor:before {
  content: "\e08a";
}
.dti-anticlockwise:before {
  content: "\e08b";
}
.dti-archive:before {
  content: "\e08c";
}
.dti-archive-full:before {
  content: "\e08d";
}
.dti-ban:before {
  content: "\e08e";
}
.dti-battery-charge:before {
  content: "\e08f";
}
.dti-battery-empty:before {
  content: "\e090";
}
.dti-battery-full:before {
  content: "\e091";
}
.dti-battery-half:before {
  content: "\e092";
}
.dti-bolt:before {
  content: "\e093";
}
.dti-book:before {
  content: "\e094";
}
.dti-book-pen:before {
  content: "\e095";
}
.dti-book-pencil:before {
  content: "\e096";
}
.dti-bookmark:before {
  content: "\e097";
}
.dti-calculator:before {
  content: "\e098";
}
.dti-calendar:before {
  content: "\e099";
}
.dti-cards-diamonds:before {
  content: "\e09a";
}
.dti-cards-hearts:before {
  content: "\e09b";
}
.dti-case:before {
  content: "\e09c";
}
.dti-chronometer:before {
  content: "\e09d";
}
.dti-clessidre:before {
  content: "\e09e";
}
.dti-clock:before {
  content: "\e09f";
}
.dti-clockwise:before {
  content: "\e0a0";
}
.dti-cloud:before {
  content: "\e0a1";
}
.dti-clubs:before {
  content: "\e0a2";
}
.dti-compass:before {
  content: "\e0a3";
}
.dti-cup:before {
  content: "\e0a4";
}
.dti-diamonds:before {
  content: "\e0a5";
}
.dti-display:before {
  content: "\e0a6";
}
.dti-download:before {
  content: "\e0a7";
}
.dti-eye:before {
  content: "\e0a9";
}
.dti-eye-closed:before {
  content: "\e0aa";
}
.dti-female:before {
  content: "\e0ab";
}
.dti-flag:before {
  content: "\e0ac";
}
.dti-flag2:before {
  content: "\e0ad";
}
.dti-floppy-disk:before {
  content: "\e0ae";
}
.dti-folder:before {
  content: "\e0af";
}
.dti-folders:before {
  content: "\e0b0";
}
.dti-gear:before {
  content: "\e0b1";
}
.dti-geolocalize:before {
  content: "\e0b2";
}
.dti-geolocalize2:before {
  content: "\e0b3";
}
.dti-globe:before {
  content: "\e0b4";
}
.dti-gunsight:before {
  content: "\e0b5";
}
.dti-hammer:before {
  content: "\e0b6";
}
.dti-headset:before {
  content: "\e0b7";
}
.dti-heart:before {
  content: "\e0b8";
}
.dti-heart-broken:before {
  content: "\e0b9";
}
.dti-helm:before {
  content: "\e0ba";
}
.dti-home:before {
  content: "\e0bb";
}
.dti-ipod:before {
  content: "\e0bd";
}
.dti-joypad:before {
  content: "\e0be";
}
.dti-key:before {
  content: "\e0bf";
}
.dti-keyboard:before {
  content: "\e0c0";
}
.dti-laptop:before {
  content: "\e0c1";
}
.dti-life-buoy:before {
  content: "\e0c2";
}
.dti-lightbulb:before {
  content: "\e0c3";
}
.dti-link:before {
  content: "\e0c4";
}
.dti-lock:before {
  content: "\e0c5";
}
.dti-lock-open:before {
  content: "\e0c6";
}
.dti-magic-mouse:before {
  content: "\e0c7";
}
.dti-magnifier:before {
  content: "\e0c8";
}
.dti-magnifier-minus:before {
  content: "\e0c9";
}
.dti-magnifier-plus:before {
  content: "\e0ca";
}
.dti-accelerator:before {
  content: "\e0cb";
}
.dti-mails:before {
  content: "\e0cc";
}
.dti-mail-open:before {
  content: "\e0cd";
}
.dti-mail-open-text:before {
  content: "\e0ce";
}
.dti-male:before {
  content: "\e0cf";
}
.dti-map:before {
  content: "\e0d0";
}
.dti-message:before {
  content: "\e0d1";
}
.dti-messages:before {
  content: "\e0d2";
}
.dti-message-text:before {
  content: "\e0d3";
}
.dti-mixer:before {
  content: "\e0d4";
}
.dti-mouse:before {
  content: "\e0d5";
}
.dti-notebook:before {
  content: "\e0d6";
}
.dti-notebook-pen:before {
  content: "\e0d7";
}
.dti-notebook-pencil:before {
  content: "\e0d8";
}
.dti-paper-plane:before {
  content: "\e0d9";
}
.dti-pencil-ruler:before {
  content: "\e0da";
}
.dti-pencil-ruler-pen:before {
  content: "\e0db";
}
.dti-photo:before {
  content: "\e0dc";
}
.dti-picture:before {
  content: "\e0dd";
}
.dti-pictures:before {
  content: "\e0de";
}
.dti-pin:before {
  content: "\e0df";
}
.dti-pin2:before {
  content: "\e0e0";
}
.dti-postcard:before {
  content: "\e0e1";
}
.dti-postcards:before {
  content: "\e0e2";
}
.dti-printer:before {
  content: "\e0e3";
}
.dti-rss:before {
  content: "\e0e5";
}
.dti-server:before {
  content: "\e0e6";
}
.dti-server2:before {
  content: "\e0e7";
}
.dti-server-cloud:before {
  content: "\e0e8";
}
.dti-server-download:before {
  content: "\e0e9";
}
.dti-server-upload:before {
  content: "\e0ea";
}
.dti-settings:before {
  content: "\e0eb";
}
.dti-share:before {
  content: "\e0ec";
}
.dti-sheet:before {
  content: "\e0ed";
}
.dti-sheets:before {
  content: "\e0ee";
}
.dti-sheet-pen:before {
  content: "\e0ef";
}
.dti-sheet-pencil:before {
  content: "\e0f0";
}
.dti-sheet-text:before {
  content: "\e0f1";
}
.dti-signs:before {
  content: "\e0f2";
}
.dti-smartphone:before {
  content: "\e0f3";
}
.dti-spades:before {
  content: "\e0f4";
}
.dti-spread:before {
  content: "\e0f5";
}
.dti-spread-book:before {
  content: "\e0f6";
}
.dti-spread-text:before {
  content: "\e0f7";
}
.dti-spread-text-book:before {
  content: "\e0f8";
}
.dti-star:before {
  content: "\e0f9";
}
.dti-tablet:before {
  content: "\e0fa";
}
.dti-target:before {
  content: "\e0fb";
}
.dti-todo:before {
  content: "\e0fc";
}
.dti-todo-pen:before {
  content: "\e0fd";
}
.dti-todo-pencil:before {
  content: "\e0fe";
}
.dti-todo-text:before {
  content: "\e0ff";
}
.dti-todo-text-pen:before {
  content: "\e100";
}
.dti-todo-text-pencil:before {
  content: "\e101";
}
.dti-trashcan:before {
  content: "\e102";
}
.dti-trashcan-full:before {
  content: "\e103";
}
.dti-trashcan-refresh:before {
  content: "\e104";
}
.dti-trashcan-remove:before {
  content: "\e105";
}
.dti-upload:before {
  content: "\e106";
}
.dti-usb:before {
  content: "\e107";
}
.dti-video:before {
  content: "\e108";
}
.dti-watch:before {
  content: "\e109";
}
.dti-webpage:before {
  content: "\e10a";
}
.dti-webpage-image-text:before {
  content: "\e10b";
}
.dti-webpages:before {
  content: "\e10c";
}
.dti-webpage-text:before {
  content: "\e10d";
}
.dti-world:before {
  content: "\e10e";
}
.dti-layout-sidebar-left:before {
  content: "\e10f";
}
.dti-add-vectorpoint:before {
  content: "\e110";
}
.dti-box-polygon:before {
  content: "\e111";
}
.dti-box-rectangle:before {
  content: "\e112";
}
.dti-box-rounded-rectangle:before {
  content: "\e113";
}
.dti-character:before {
  content: "\e114";
}
.dti-crop:before {
  content: "\e115";
}
.dti-eyedropper:before {
  content: "\e116";
}
.dti-font-allcaps:before {
  content: "\e117";
}
.dti-font-baseline-shift:before {
  content: "\e118";
}
.dti-font-horizontal-scale:before {
  content: "\e119";
}
.dti-font-kerning:before {
  content: "\e11a";
}
.dti-font-leading:before {
  content: "\e11b";
}
.dti-font-size:before {
  content: "\e11c";
}
.dti-font-smallcapital:before {
  content: "\e11d";
}
.dti-font-smallcaps:before {
  content: "\e11e";
}
.dti-font-strikethrough:before {
  content: "\e11f";
}
.dti-font-tracking:before {
  content: "\e120";
}
.dti-font-underline:before {
  content: "\e121";
}
.dti-font-vertical-scale:before {
  content: "\e122";
}
.dti-horizontal-align-center:before {
  content: "\e123";
}
.dti-horizontal-align-left:before {
  content: "\e124";
}
.dti-horizontal-align-right:before {
  content: "\e125";
}
.dti-horizontal-distribute-center:before {
  content: "\e126";
}
.dti-horizontal-distribute-left:before {
  content: "\e127";
}
.dti-horizontal-distribute-right:before {
  content: "\e128";
}
.dti-indent-firstline:before {
  content: "\e129";
}
.dti-indent-left:before {
  content: "\e12a";
}
.dti-indent-right:before {
  content: "\e12b";
}
.dti-lasso:before {
  content: "\e12c";
}
.dti-layers:before {
  content: "\e12d";
}
.dti-layers2:before {
  content: "\e12e";
}
.dti-layout:before {
  content: "\e12f";
}
.dti-layout-2columns:before {
  content: "\e130";
}
.dti-layout-3columns:before {
  content: "\e131";
}
.dti-layout-4boxes:before {
  content: "\e132";
}
.dti-layout-4columns:before {
  content: "\e133";
}
.dti-layout-4lines:before {
  content: "\e134";
}
.dti-layout-8boxes:before {
  content: "\e135";
}
.dti-layout-header:before {
  content: "\e136";
}
.dti-layout-header-2columns:before {
  content: "\e137";
}
.dti-layout-header-3columns:before {
  content: "\e138";
}
.dti-layout-header-4boxes:before {
  content: "\e139";
}
.dti-layout-header-4columns:before {
  content: "\e13a";
}
.dti-layout-header-complex:before {
  content: "\e13b";
}
.dti-layout-header-complex2:before {
  content: "\e13c";
}
.dti-layout-header-complex3:before {
  content: "\e13d";
}
.dti-layout-header-complex4:before {
  content: "\e13e";
}
.dti-layout-header-sideleft:before {
  content: "\e13f";
}
.dti-layout-header-sideright:before {
  content: "\e140";
}
.dti-box-oval:before {
  content: "\e141";
}
.dti-layout-sidebar-right:before {
  content: "\e142";
}
.dti-magnete:before {
  content: "\e143";
}
.dti-pages:before {
  content: "\e144";
}
.dti-paint-brush:before {
  content: "\e145";
}
.dti-paint-bucket:before {
  content: "\e146";
}
.dti-paint-roller:before {
  content: "\e147";
}
.dti-paragraph:before {
  content: "\e148";
}
.dti-paragraph-align-left:before {
  content: "\e149";
}
.dti-paragraph-align-right:before {
  content: "\e14a";
}
.dti-paragraph-align-center:before {
  content: "\e14b";
}
.dti-paragraph-justify-all:before {
  content: "\e14c";
}
.dti-paragraph-justify-center:before {
  content: "\e14d";
}
.dti-paragraph-justify-left:before {
  content: "\e14e";
}
.dti-paragraph-justify-right:before {
  content: "\e14f";
}
.dti-paragraph-space-after:before {
  content: "\e150";
}
.dti-paragraph-space-before:before {
  content: "\e151";
}
.dti-pathfinder-exclude:before {
  content: "\e152";
}
.dti-pathfinder-intersect:before {
  content: "\e153";
}
.dti-pathfinder-subtract:before {
  content: "\e154";
}
.dti-pathfinder-unite:before {
  content: "\e155";
}
.dti-pen:before {
  content: "\e156";
}
.dti-pen-add:before {
  content: "\e157";
}
.dti-pen-minus:before {
  content: "\e158";
}
.dti-pencil:before {
  content: "\e159";
}
.dti-polygona:before {
  content: "\e15a";
}
.dti-reflect-horizontal:before {
  content: "\e15b";
}
.dti-reflect-vertical:before {
  content: "\e15c";
}
.dti-remove-vectorpoint:before {
  content: "\e15d";
}
.dti-scale-expand:before {
  content: "\e15e";
}
.dti-scale-reduce:before {
  content: "\e15f";
}
.dti-selection-oval:before {
  content: "\e160";
}
.dti-selection-polygon:before {
  content: "\e161";
}
.dti-selection-rectangle:before {
  content: "\e162";
}
.dti-selection-rounded-rectangleselection-rounded-rectangle:before {
  content: "\e163";
}
.dti-shape-oval:before {
  content: "\e164";
}
.dti-shape-polygon:before {
  content: "\e165";
}
.dti-shape-rectangle:before {
  content: "\e166";
}
.dti-shape-rounded-rectangle:before {
  content: "\e167";
}
.dti-slice:before {
  content: "\e168";
}
.dti-transform-bezier:before {
  content: "\e169";
}
.dti-vector-box:before {
  content: "\e16a";
}
.dti-vector-composite:before {
  content: "\e16b";
}
.dti-vector-line:before {
  content: "\e16c";
}
.dti-vertical-align-bottom:before {
  content: "\e16d";
}
.dti-vertical-align-center:before {
  content: "\e16e";
}
.dti-vertical-align-top:before {
  content: "\e16f";
}
.dti-vertical-distribute-bottom:before {
  content: "\e170";
}
.dti-vertical-distribute-center:before {
  content: "\e171";
}
.dti-vertical-distribute-top:before {
  content: "\e172";
}
.dti-rain-sun:before {
  content: "\e173";
}
.dti-aquarius:before {
  content: "\e174";
}
.dti-cancer:before {
  content: "\e175";
}
.dti-capricorn:before {
  content: "\e176";
}
.dti-cloud-drop:before {
  content: "\e178";
}
.dti-cloud-lightning:before {
  content: "\e179";
}
.dti-cloud-snowflake:before {
  content: "\e17a";
}
.dti-downpour-full-moon:before {
  content: "\e17b";
}
.dti-downpour-half-moon:before {
  content: "\e17c";
}
.dti-downpour-sun:before {
  content: "\e17d";
}
.dti-drop:before {
  content: "\e17e";
}
.dti-first-quarter:before {
  content: "\e17f";
}
.dti-fog:before {
  content: "\e180";
}
.dti-fog-full-moon:before {
  content: "\e181";
}
.dti-fog-half-moon:before {
  content: "\e182";
}
.dti-fog-sun:before {
  content: "\e183";
}
.dti-full-moon:before {
  content: "\e184";
}
.dti-gemini:before {
  content: "\e185";
}
.dti-hail:before {
  content: "\e186";
}
.dti-hail-full-moon:before {
  content: "\e187";
}
.dti-hail-half-moon:before {
  content: "\e188";
}
.dti-hail-sun:before {
  content: "\e189";
}
.dti-last-quarter:before {
  content: "\e18a";
}
.dti-leo:before {
  content: "\e18b";
}
.dti-libra:before {
  content: "\e18c";
}
.dti-lightning:before {
  content: "\e18d";
}
.dti-misty-rain:before {
  content: "\e18e";
}
.dti-misty-rain-full-moon:before {
  content: "\e18f";
}
.dti-misty-rain-half-moon:before {
  content: "\e190";
}
.dti-misty-rain-sun:before {
  content: "\e191";
}
.dti-moon:before {
  content: "\e192";
}
.dti-moondown-full:before {
  content: "\e193";
}
.dti-moondown-half:before {
  content: "\e194";
}
.dti-moonset-full:before {
  content: "\e195";
}
.dti-moonset-half:before {
  content: "\e196";
}
.dti-newmoon:before {
  content: "\e198";
}
.dti-pisces:before {
  content: "\e199";
}
.dti-rain:before {
  content: "\e19a";
}
.dti-rain-full-moon:before {
  content: "\e19b";
}
.dti-rain-half-moon:before {
  content: "\e19c";
}
.dti-aries:before {
  content: "\e19d";
}
.dti-sagittarius:before {
  content: "\e19e";
}
.dti-scorpio:before {
  content: "\e19f";
}
.dti-snow:before {
  content: "\e1a0";
}
.dti-snow-full-moon:before {
  content: "\e1a1";
}
.dti-snow-half-moon:before {
  content: "\e1a2";
}
.dti-snow-sun:before {
  content: "\e1a3";
}
.dti-snow-flake:before {
  content: "\e1a4";
}
.dti-star2:before {
  content: "\e1a5";
}
.dti-storm:before {
  content: "\e1a6";
}
.dti-storm2:before {
  content: "\e1a7";
}
.dti-storm-full-moon:before {
  content: "\e1a8";
}
.dti-storm-half-moon:before {
  content: "\e1a9";
}
.dti-storm-sun:before {
  content: "\e1aa";
}
.dti-sun:before {
  content: "\e1ab";
}
.dti-sundown:before {
  content: "\e1ac";
}
.dti-sunset:before {
  content: "\e1ad";
}
.dti-taurus:before {
  content: "\e1ae";
}
.dti-tempest:before {
  content: "\e1af";
}
.dti-tempest-full-moon:before {
  content: "\e1b0";
}
.dti-tempest-half-moon:before {
  content: "\e1b1";
}
.dti-tempest-sun:before {
  content: "\e1b2";
}
.dti-variable-full-moon:before {
  content: "\e1b3";
}
.dti-variable-half-moon:before {
  content: "\e1b4";
}
.dti-variable-sun:before {
  content: "\e1b5";
}
.dti-virgo:before {
  content: "\e1b6";
}
.dti-waning-cresent:before {
  content: "\e1b7";
}
.dti-waning-gibbous:before {
  content: "\e1b8";
}
.dti-waxing-cresent:before {
  content: "\e1b9";
}
.dti-waxing-gibbous:before {
  content: "\e1ba";
}
.dti-wind:before {
  content: "\e1bb";
}
.dti-circle-wind-e:before {
  content: "\e1bc";
}
.dti-wind-full-moon:before {
  content: "\e1bd";
}
.dti-wind-half-moon:before {
  content: "\e1be";
}
.dti-circle-wind-n:before {
  content: "\e1bf";
}
.dti-circle-wind-ne:before {
  content: "\e1c0";
}
.dti-circle-wind-nw:before {
  content: "\e1c1";
}
.dti-circle-wind-s:before {
  content: "\e1c2";
}
.dti-circle-wind-se:before {
  content: "\e1c3";
}
.dti-wind-sun:before {
  content: "\e1c4";
}
.dti-circle-wind-sw:before {
  content: "\e1c5";
}
.dti-circle-wind-w:before {
  content: "\e1c6";
}
.dti-weather-windgust:before {
  content: "\e1c7";
}
.dti-graph:before {
  content: "\e259";
}
.dti-bag:before {
  content: "\e25a";
}
.dti-bag-cloud:before {
  content: "\e25b";
}
.dti-bag-download:before {
  content: "\e25c";
}
.dti-bag-minus:before {
  content: "\e25d";
}
.dti-bag-plus:before {
  content: "\e25e";
}
.dti-bag-refresh:before {
  content: "\e25f";
}
.dti-bag-remove:before {
  content: "\e260";
}
.dti-bag-search:before {
  content: "\e261";
}
.dti-bag-upload:before {
  content: "\e262";
}
.dti-banknote:before {
  content: "\e263";
}
.dti-banknotes:before {
  content: "\e264";
}
.dti-basket:before {
  content: "\e265";
}
.dti-basket-check:before {
  content: "\e266";
}
.dti-basket-cloud:before {
  content: "\e267";
}
.dti-basket-download:before {
  content: "\e268";
}
.dti-basket-minus:before {
  content: "\e269";
}
.dti-basket-plus:before {
  content: "\e26a";
}
.dti-basket-refresh:before {
  content: "\e26b";
}
.dti-basket-remove:before {
  content: "\e26c";
}
.dti-basket-search:before {
  content: "\e26d";
}
.dti-basket-upload:before {
  content: "\e26e";
}
.dti-circle-bath:before {
  content: "\e26f";
}
.dti-cart:before {
  content: "\e270";
}
.dti-cart-check:before {
  content: "\e271";
}
.dti-cart-cloud:before {
  content: "\e272";
}
.dti-cart-content:before {
  content: "\e273";
}
.dti-cart-download:before {
  content: "\e274";
}
.dti-cart-minus:before {
  content: "\e275";
}
.dti-cart-plus:before {
  content: "\e276";
}
.dti-cart-refresh:before {
  content: "\e277";
}
.dti-cart-remove:before {
  content: "\e278";
}
.dti-cart-search:before {
  content: "\e279";
}
.dti-cart-upload:before {
  content: "\e27a";
}
.dti-circle-cent:before {
  content: "\e27b";
}
.dti-circle-colon:before {
  content: "\e27c";
}
.dti-credit-card:before {
  content: "\e27d";
}
.dti-diamond:before {
  content: "\e27e";
}
.dti-circle-doller:before {
  content: "\e27f";
}
.dti-circle-euro:before {
  content: "\e280";
}
.dti-circle-franc:before {
  content: "\e281";
}
.dti-gift:before {
  content: "\e282";
}
.dti-bag-check:before {
  content: "\e283";
}
.dti-graph2:before {
  content: "\e284";
}
.dti-graph3:before {
  content: "\e285";
}
.dti-graph-decrease:before {
  content: "\e286";
}
.dti-graph-increase:before {
  content: "\e287";
}
.dti-circle-guarani:before {
  content: "\e288";
}
.dti-circle-kips:before {
  content: "\e289";
}
.dti-circle-lira:before {
  content: "\e28a";
}
.dti-megaphone:before {
  content: "\e28b";
}
.dti-money:before {
  content: "\e28c";
}
.dti-circle-naira:before {
  content: "\e28d";
}
.dti-circle-pesos:before {
  content: "\e28e";
}
.dti-circle-pound:before {
  content: "\e28f";
}
.dti-receipt:before {
  content: "\e290";
}
.dti-receipt-bath:before {
  content: "\e291";
}
.dti-receipt-cent:before {
  content: "\e292";
}
.dti-receipt-dollar:before {
  content: "\e293";
}
.dti-receipt-euro:before {
  content: "\e294";
}
.dti-receipt-franc:before {
  content: "\e295";
}
.dti-receipt-guarani:before {
  content: "\e296";
}
.dti-receipt-kips:before {
  content: "\e297";
}
.dti-receipt-lira:before {
  content: "\e298";
}
.dti-receipt-naira:before {
  content: "\e299";
}
.dti-receipt-pesos:before {
  content: "\e29a";
}
.dti-receipt-pound:before {
  content: "\e29b";
}
.dti-receipt-rublo:before {
  content: "\e29c";
}
.dti-receipt-rupee:before {
  content: "\e29d";
}
.dti-receipt-tugrik:before {
  content: "\e29e";
}
.dti-receipt-won:before {
  content: "\e29f";
}
.dti-receipt-yen:before {
  content: "\e2a0";
}
.dti-receipt-yen2:before {
  content: "\e2a1";
}
.dti-receipt-colon:before {
  content: "\e2a2";
}
.dti-circle-rublo:before {
  content: "\e2a3";
}
.dti-circle-rupee:before {
  content: "\e2a4";
}
.dti-safe:before {
  content: "\e2a5";
}
.dti-sale:before {
  content: "\e2a6";
}
.dti-sales:before {
  content: "\e2a7";
}
.dti-ticket:before {
  content: "\e2a8";
}
.dti-circle-tugriks:before {
  content: "\e2a9";
}
.dti-wallet:before {
  content: "\e2aa";
}
.dti-circle-won:before {
  content: "\e2ab";
}
.dti-circle-yen:before {
  content: "\e2ac";
}
.dti-circle-yen2:before {
  content: "\e2ad";
}
.dti-music-note-single:before {
  content: "\e2ae";
}
.dti-circle-beginning:before {
  content: "\e2af";
}
.dti-cd:before {
  content: "\e2b0";
}
.dti-diapason:before {
  content: "\e2b1";
}
.dti-circle-eject:before {
  content: "\e2b2";
}
.dti-circle-end:before {
  content: "\e2b3";
}
.dti-circle-fast-forward:before {
  content: "\e2b4";
}
.dti-headphone:before {
  content: "\e2b5";
}
.dti-loudspeaker:before {
  content: "\e2b7";
}
.dti-microphone:before {
  content: "\e2b8";
}
.dti-microphone-old:before {
  content: "\e2b9";
}
.dti-mixer-square:before {
  content: "\e2ba";
}
.dti-mute:before {
  content: "\e2bb";
}
.dti-music-note-multiple:before {
  content: "\e2bc";
}
.dti-bell:before {
  content: "\e2bd";
}
.dti-circle-pause:before {
  content: "\e2be";
}
.dti-circle-play:before {
  content: "\e2bf";
}
.dti-playlist:before {
  content: "\e2c0";
}
.dti-radio:before {
  content: "\e2c1";
}
.dti-radio-portable:before {
  content: "\e2c2";
}
.dti-record:before {
  content: "\e2c3";
}
.dti-record-player:before {
  content: "\e2c4";
}
.dti-repeat-button:before {
  content: "\e2c5";
}
.dti-circle-rewind:before {
  content: "\e2c6";
}
.dti-shuffle-button:before {
  content: "\e2c7";
}
.dti-circle-stop:before {
  content: "\e2c8";
}
.dti-tape:before {
  content: "\e2c9";
}
.dti-volume-down:before {
  content: "\e2ca";
}
.dti-volume-up:before {
  content: "\e2cb";
}
.dti-folder-flagged:before {
  content: "\e177";
}
.dti-bookmark-check:before {
  content: "\e197";
}
.dti-bookmark-plus:before {
  content: "\e1c8";
}
.dti-bookmark-remove:before {
  content: "\e1c9";
}
.dti-briefcase-check:before {
  content: "\e1ca";
}
.dti-briefcase-download:before {
  content: "\e1cb";
}
.dti-briefcase-flagged:before {
  content: "\e1cc";
}
.dti-briefcase-minus:before {
  content: "\e1cd";
}
.dti-briefcase-plus:before {
  content: "\e1ce";
}
.dti-briefcase-refresh:before {
  content: "\e1cf";
}
.dti-briefcase-remove:before {
  content: "\e1d0";
}
.dti-briefcase-search:before {
  content: "\e1d1";
}
.dti-briefcase-star:before {
  content: "\e1d2";
}
.dti-briefcase-upload:before {
  content: "\e1d3";
}
.dti-browser-check:before {
  content: "\e1d4";
}
.dti-browser-download:before {
  content: "\e1d5";
}
.dti-browser-minus:before {
  content: "\e1d6";
}
.dti-browser-plus:before {
  content: "\e1d7";
}
.dti-browser-refresh:before {
  content: "\e1d8";
}
.dti-browser-remove:before {
  content: "\e1d9";
}
.dti-browser-search:before {
  content: "\e1da";
}
.dti-browser-star:before {
  content: "\e1db";
}
.dti-browser-upload:before {
  content: "\e1dc";
}
.dti-calendar-check:before {
  content: "\e1dd";
}
.dti-calendar-cloud:before {
  content: "\e1de";
}
.dti-calendar-download:before {
  content: "\e1df";
}
.dti-calendar-empty:before {
  content: "\e1e0";
}
.dti-calendar-flagged:before {
  content: "\e1e1";
}
.dti-calendar-heart:before {
  content: "\e1e2";
}
.dti-calendar-minus:before {
  content: "\e1e3";
}
.dti-calendar-next:before {
  content: "\e1e4";
}
.dti-calendar-disable:before {
  content: "\e1e5";
}
.dti-calendar-pencil:before {
  content: "\e1e6";
}
.dti-calendar-plus:before {
  content: "\e1e7";
}
.dti-calendar-prev:before {
  content: "\e1e8";
}
.dti-calendar-refresh:before {
  content: "\e1e9";
}
.dti-calendar-remove:before {
  content: "\e1ea";
}
.dti-calendar-search:before {
  content: "\e1eb";
}
.dti-calendar-star:before {
  content: "\e1ec";
}
.dti-calendar-upload:before {
  content: "\e1ed";
}
.dti-cloud-check:before {
  content: "\e1ee";
}
.dti-cloud-download:before {
  content: "\e1ef";
}
.dti-cloud-minus:before {
  content: "\e1f0";
}
.dti-cloud-disable:before {
  content: "\e1f1";
}
.dti-cloud-plus:before {
  content: "\e1f2";
}
.dti-cloud-refresh:before {
  content: "\e1f3";
}
.dti-cloud-remove:before {
  content: "\e1f4";
}
.dti-cloud-search:before {
  content: "\e1f5";
}
.dti-cloud-upload:before {
  content: "\e1f6";
}
.dti-document-check:before {
  content: "\e1f7";
}
.dti-document-cloud:before {
  content: "\e1f8";
}
.dti-document-download:before {
  content: "\e1f9";
}
.dti-document-flagged:before {
  content: "\e1fa";
}
.dti-document-graph:before {
  content: "\e1fb";
}
.dti-document-heart:before {
  content: "\e1fc";
}
.dti-document-minus:before {
  content: "\e1fd";
}
.dti-document-next:before {
  content: "\e1fe";
}
.dti-document-disable:before {
  content: "\e1ff";
}
.dti-document-music:before {
  content: "\e200";
}
.dti-document-pencil:before {
  content: "\e201";
}
.dti-document-picture:before {
  content: "\e202";
}
.dti-document-plus:before {
  content: "\e203";
}
.dti-document-prev:before {
  content: "\e204";
}
.dti-document-refresh:before {
  content: "\e205";
}
.dti-document-remove:before {
  content: "\e206";
}
.dti-document-search:before {
  content: "\e207";
}
.dti-document-star:before {
  content: "\e208";
}
.dti-document-upload:before {
  content: "\e209";
}
.dti-folder-check:before {
  content: "\e20a";
}
.dti-folder-cloud:before {
  content: "\e20b";
}
.dti-folder-document:before {
  content: "\e20c";
}
.dti-folder-download:before {
  content: "\e20d";
}
.dti-bookmark-minus:before {
  content: "\e20e";
}
.dti-folder-graph:before {
  content: "\e20f";
}
.dti-folder-heart:before {
  content: "\e210";
}
.dti-folder-minus:before {
  content: "\e211";
}
.dti-folder-next:before {
  content: "\e212";
}
.dti-folder-disable:before {
  content: "\e213";
}
.dti-folder-music:before {
  content: "\e214";
}
.dti-folder-pencil:before {
  content: "\e215";
}
.dti-folder-picture:before {
  content: "\e216";
}
.dti-folder-plus:before {
  content: "\e217";
}
.dti-folder-prev:before {
  content: "\e218";
}
.dti-folder-refresh:before {
  content: "\e219";
}
.dti-folder-remove:before {
  content: "\e21a";
}
.dti-folder-search:before {
  content: "\e21b";
}
.dti-folder-star:before {
  content: "\e21c";
}
.dti-folder-upload:before {
  content: "\e21d";
}
.dti-mail-check:before {
  content: "\e21e";
}
.dti-mail-cloud:before {
  content: "\e21f";
}
.dti-mail-document:before {
  content: "\e220";
}
.dti-mail-download:before {
  content: "\e221";
}
.dti-mail-flagged:before {
  content: "\e222";
}
.dti-mail-heart:before {
  content: "\e223";
}
.dti-mail-next:before {
  content: "\e224";
}
.dti-mail-disable:before {
  content: "\e225";
}
.dti-mail-music:before {
  content: "\e226";
}
.dti-mail-pencil:before {
  content: "\e227";
}
.dti-mail-picture:before {
  content: "\e228";
}
.dti-mail-prev:before {
  content: "\e229";
}
.dti-mail-refresh:before {
  content: "\e22a";
}
.dti-mail-remove:before {
  content: "\e22b";
}
.dti-mail-search:before {
  content: "\e22c";
}
.dti-mail-star:before {
  content: "\e22d";
}
.dti-mail-upload:before {
  content: "\e22e";
}
.dti-message-check:before {
  content: "\e22f";
}
.dti-message-dots:before {
  content: "\e230";
}
.dti-message-smile:before {
  content: "\e231";
}
.dti-message-heart:before {
  content: "\e232";
}
.dti-message-minus:before {
  content: "\e233";
}
.dti-message-music:before {
  content: "\e234";
}
.dti-message-plus:before {
  content: "\e235";
}
.dti-message-refresh:before {
  content: "\e236";
}
.dti-message-remove:before {
  content: "\e237";
}
.dti-message-sad:before {
  content: "\e238";
}
.dti-smartphone-cloud:before {
  content: "\e239";
}
.dti-smartphone-heart:before {
  content: "\e23a";
}
.dti-smartphone-disable:before {
  content: "\e23b";
}
.dti-smartphone-music:before {
  content: "\e23c";
}
.dti-smartphone-pencil:before {
  content: "\e23d";
}
.dti-smartphone-picture:before {
  content: "\e23e";
}
.dti-smartphone-refresh:before {
  content: "\e23f";
}
.dti-smartphone-search:before {
  content: "\e240";
}
.dti-tablet-cloud:before {
  content: "\e241";
}
.dti-tablet-heart:before {
  content: "\e242";
}
.dti-tablet-disable:before {
  content: "\e243";
}
.dti-tablet-music:before {
  content: "\e244";
}
.dti-tablet-pencil:before {
  content: "\e245";
}
.dti-tablet-picture:before {
  content: "\e246";
}
.dti-tablet-refresh:before {
  content: "\e247";
}
.dti-tablet-search:before {
  content: "\e248";
}
.dti-todolist:before {
  content: "\e249";
}
.dti-todolist-check:before {
  content: "\e24a";
}
.dti-todolist-cloud:before {
  content: "\e24b";
}
.dti-todolist-download:before {
  content: "\e24c";
}
.dti-todolist-flagged:before {
  content: "\e24d";
}
.dti-todolist-minus:before {
  content: "\e24e";
}
.dti-todolist-disable:before {
  content: "\e24f";
}
.dti-todolist-pencil:before {
  content: "\e250";
}
.dti-todolist-plus:before {
  content: "\e251";
}
.dti-todolist-refresh:before {
  content: "\e252";
}
.dti-todolist-remove:before {
  content: "\e253";
}
.dti-todolist-search:before {
  content: "\e254";
}
.dti-todolist-star:before {
  content: "\e255";
}
.dti-todolist-upload:before {
  content: "\e256";
}
