.footer-description {
	font-size: 1.142em;
	line-height: 1.5em;
	letter-spacing: 0.020em;
}
.contact-address {
	font-weight: 400;
	letter-spacing: 0.050em;

	> li {
		margin-bottom: 15px;
	}
}
.copyright {
	background-color: #000;
	padding: 40px 0;
	margin-bottom: 40px;
	font-size: 0.857em;
	font-weight: 400;
	letter-spacing: 0.10em;
	color: #FFF;

	a {
		color: #ea2e2b;

		&:hover {
			color: darken(#ea2e2b, 10%);
		}
	}
}