.widget_tag_cloud {
	
	> .tagcloud {
		margin-left: -5px;
		margin-right: -5px;
		> a {
			display: inline-block;
			padding: 10px 25px;
			color: #000;
			border: 1px solid #d3d3d3;
			font-size: 1.142em !important;
			letter-spacing: 0.050em;
			margin: 5px;

			&:hover {
				color: #ea2e2b;
				border-color: #ea2e2b;
			}
		}
	}
}