.dt-btn {
	border: 1px solid #ea2e2b;
	display: inline-block;
	padding: 10px 15px;
	color: #ea2e2b;
	font-size: 0.857em;
	line-height: 1em;
	font-weight: 400;
	background-color: transparent;
	transition: all 0.15s ease-in-out;

	&:hover,
	&:focus {
		background-color: #ea2e2b;
		color: #FFF;
		text-decoration: none;
		outline-color: transparent;
	}

	&.medium {
		padding: 15px 25px;
	}
}