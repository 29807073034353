.color-switcher {
	width: 250px;
	position: fixed;
	left: -250px;
	top: 200px;
	z-index: 555;
	padding: 0px;
	transition: all 0.5s ease-in-out;
	
	&.active {
		left: 0;
	}

	p {
		font-size: 1.42em;
	}
	ul {
		list-style: none;
		padding: 0;

		> li {
			margin: 3px;
			display: inline-block;

			> a {
				display: block;
				width: 40px;
				height: 40px;

				&.blue {
					background: #428bca;
				}
				&.green {
					background: #88c136;
				}
				&.red {
					background: #e54242;
				}
				&.yellow {
					background: #ff9c00;
				}
				&.brown {
					background: #987654;
				}
				&.cyan {
					background: #1ABC9C;
				}
				&.purple {
					background: #c74a73;
				}
				&.sky-blue {
					background: #00cdcd;
				}
			}
		}
	}

	.theme-colors {
		display: table;
		text-align: left;
		padding-top: 0px;
		width: 100%;
		padding: 18px;
		background: #F5F5F5;
		padding-top: 0px;
	}

	p {
		font-size: 14px;
		margin-top: 25px;
	}
}

#picker-toggle {
	width: 60px;
	height: 60px;
	position: absolute;
	right: -60px;
	top: 0px;
	z-index: -1;
	text-align: center;
	background: #F5F5F5;
	color: #ea2e2b;

	> i {
		font-size: 30px;
		line-height: 60px;
	} 
}

@media (max-width: 768px) {
	.color-switcher {
		display: none;
	}
}