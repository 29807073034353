.widget_popular_posts {

	> .popular-posts {
		padding-left: 0;

		> li {
			list-style: none;
			overflow: hidden;
			padding-bottom: 30px;
			margin-bottom: 30px;
			border-bottom: 1px solid #dcdcdc;

			&:last-child {
				border-bottom: none;
			}

			> .thumb {
				width: 90px;
				height: 90px;
				float: left;

				> img {
					max-width: 100%;
					height: auto;
				}
			}

			> .content {
				padding-left: 110px;
				font-family: 'Open Sans', sans-serif;

				.title {
					margin-top: 0;
					font-weight: 400;
					font-size: 1em;
					line-height: 1.571em;
					margin-bottom: 10px;

					> a {
						color: #000;

						&:hover {
							color: #ea2e2b;
						}
					}
				}

				.meta {
					color: #999999;
					font-weight: 0.928em;
					letter-spacing: 0.050em;
				}
			}
		}
	}
}