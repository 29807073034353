#header { }
#logo {
	width: 150px;
	float: left;

	> a {
		color: #000;
		font-size: 1.5em;
		font-weight: 800;
		text-transform: uppercase;

		> img {
			width: 100%;
			height: auto;

			&.transparent-logo {
				display: none;
			}
		}
	}
}
.site-header {
	min-height: 100px;
	padding: 35px 40px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #FFF;
	z-index: 500;
	transition: all 0.30s ease-in-out;

	&.scrolled {
		min-height: 40px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #F1F1F1;
	}
}

#top-social {
	margin-top: 5px;
	float: right;
	margin-right: 40px;

	> .social-icons {
		text-align: right;
	}
}

#top-menu {
	margin-top: 5px;
	float: right;
	position: relative;
	padding-right: 70px;
	min-width: 100px;

	.nav-trigger {
		position: absolute;
		top: 0;
		right: 0;
		width: 100px;

		> .icon-bar {
			display: inline-block;
			width: 25px;
			height: 16px;
			position: relative;

			> span {
				display: block;
				width: 100%;
				border-bottom: 2px solid #000;
				transition: all 0.30s ease-in-out;
				position: absolute;
				top: 50%;
				left: 0;

				&:first-child {
					transform: rotate(0deg);
					top: 0px;
				}
				&:nth-child(2) {
					top: 7px;
					left: 0;
				}
				&:last-child {
					transform: rotate(0deg);
					bottom: 0;
				}
			}
		}
		> .text {
			display: inline-block;
			font-weight: 700;
			text-transform: uppercase;
			margin-left: 10px;
			width: 45px;
			height: 16px;
			position: relative;

			> span {
				font-size: 0.857em;
				font-weight: 400;
				letter-spacing: 0.10em;
				position: absolute;
				top: 50%;
				right: -50%;
				transition: all 0.30s ease-in-out;

				&:first-child {
					right: 50%;
					transform: translate(50%, -50%);
				}
				&:last-child {
					left: -50%;
					right: inherit;
					opacity: 0;
					transform: translate(-50%, -50%);
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}

	.menu-items {
		margin: 0;
		padding: 0;
		margin-right: 85px;
		display: none;

		> li {
			display: inline-block;
			opacity: 0;
			position: relative;
			top: -5px;
			transition: top 0.3s ease 0.1s, opacity 0.3s ease 0.1s;

			&:nth-child(2n) { transition: top 0.3s ease 0.2s, opacity 0.3s ease 0.2s; }
			&:nth-child(3n) { transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.3s; }
			&:nth-child(4n) { transition: top 0.3s ease 0.4s, opacity 0.3s ease 0.4s; }
			&:nth-child(5n) { transition: top 0.3s ease 0.5s, opacity 0.3s ease 0.5s; }
			&:nth-child(6n) { transition: top 0.3s ease 0.6s, opacity 0.3s ease 0.6s; }
			&:nth-child(7n) { transition: top 0.3s ease 0.7s, opacity 0.3s ease 0.7s; }
			&:nth-child(8n) { transition: top 0.3s ease 0.8s, opacity 0.3s ease 0.8s; }
			&:nth-child(9n) { transition: top 0.3s ease 0.9s, opacity 0.3s ease 0.9s; }
			&:nth-child(10n) { transition: top 0.3s ease 1.0s, opacity 0.3s ease 1.0s; }

			> a {
				text-transform: uppercase;
				color: #000;
				margin-right: 25px;
				font-size: 0.857em;
				font-weight: 400;
				letter-spacing: 0.10em;
			}

			&.active > a,
			> a:hover,
			> a:focus {
				color: #ea2e2b;
				text-decoration: none;
			}

			&:last-child > a {
				margin-right: 0;
			}
		}
	}

	&.active {
		.nav-trigger {

			> .icon-bar {
				position: relative;

				> span {
					position: absolute;
					top: 50%;
					left: 50%;
					bottom: inherit;

					&:nth-child(2) {
						opacity: 0;
						display: none;
					}

					&:first-child {
						transform: translate(-50%,-50%) rotate(45deg);
					}
					&:last-child {
						transform: translate(-50%,-50%) rotate(-45deg);
					}
				}
			}

			> .text {
				> span:first-child {
					right: 0%;
					opacity: 0
				}
				> span:last-child {
					top: 50%;
					left: 50%;
					right: inherit;
					opacity: 1;
				}
			}
		}
		.menu-items {
			display: block;

			&.active {
				> li {
					opacity: 1;
					top: 0;
				}
			}
		}
	}
}

// Transparent Menu
.transparent-menu {
	padding-top: 40px;

	.site-header {
		background-color: transparent;
		padding-top: 60px;
		
		// Transparent Logo
		#logo > a > img {
			display: none;

			&.transparent-logo {
				display: block;
			}
		}

		// Menu Links Color
		#top-menu .menu-items > li {
			> a {
				color: #FFF;
				&:hover {
					color: $red;
				}
			}

			&.active > a {
				color: $red;
			}
		}
		
		// Social Icons
		.social-icons > li > a {
			color: #FFF;
			
			&:hover {
				color: $red;
			}
		}

		// Nav Trigger Color
		#top-menu .nav-trigger > .icon-bar > span {
			border-bottom-color: #FFF;
		}
		#top-menu .nav-trigger > .text {
			color: #FFF;
		}
		
		// When page scrolled
		&.scrolled {
			padding-top: 10px;
			background-color: #FFF;

			// Transparent Logo
			#logo > a > img {
				display: block;

				&.transparent-logo {
					display: none;
				}
			}

			// Menu Links Color
			#top-menu .menu-items > li {
				> a {
					color: inherit;
					&:hover {
						color: $red;
					}
				}

				&.active > a {
					color: $red;
				}
			}

			// Social Icons
			.social-icons > li > a {
				color: #000;
				
				&:hover {
					color: $red;
				}
			}

			// Nav Trigger Color
			#top-menu .nav-trigger > .icon-bar > span {
				border-bottom-color: #000;
			}
			#top-menu .nav-trigger > .text {
				color: #000;
			}
		}

	}
}