.service-box {
	position: relative;
	margin-bottom: 90px;

	> .icon {
		transition: all 0.15s ease-in-out;

		> i {
			font-size: 4.571em;
			margin-bottom: 30px;
		}
	}

	> .details {
		position: relative;
		transition: all 0.15s ease-in-out;
		overflow: hidden;

		> i {
			position: absolute;
			right: -15px;
			top: 50%;
			font-size: 7em;
			z-index: 1;
			color: #ea2e2b;
			opacity: 0;
			transition: all 0.15s ease-in-out;
			transition-delay: 0.15s;
		}

		> .title {
			position: relative;
			font-size: 1.428em;
			font-weigh: 700;
			text-transform: uppercase;
			letter-spacing: 0.060em;
			margin: 0;
			margin-bottom: 25px;
			z-index: 5;
		}

		> .content,
		> .full {
			font-family: 'Open Sans', sans-serif;
			line-height: 1.714em;
			z-index: 5;
			transition: all 0.15s ease-in-out;
		}
		> .full {
			position: absolute;
			width: 100%;
			height: auto;
			top: 0;
			transform: translateY(100%);
			transition-delay: 0.1s;
			opacity: 0;

		}
	}

	&:hover {
		
		> .icon {
			transform: translateY(-100%);
			opacity: 0;
		}

		> .details {
			overflow: visible;
			transform: translateY(-65px);

			> i {
				opacity: 0.1;
				transform: translateY(-40%);
				// filter: blur(2px);
			}

			> .content {
				opacity: 0;
				transform: translateY(-100%);
			}
			> .full {
				opacity: 1;
				bottom: 0;
				transform: translateY(45px);
			}
		}

	}
}