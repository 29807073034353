.iconic-box {
	position: relative;
	margin-bottom: 90px;
	
	> .icon {
		margin-top: 6px;
		margin-bottom: 31px;
	}

	> .content-area {
		position: relative;

		> .title {
			font-size: 1.428em;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.06em;
			margin: 0;
			margin-bottom: 30px;
		}

		> .back-icon {
			position: absolute;
			right: 15px;
			opacity: 0.05;
			top: 0;

			> i {
				font-size: 125px;
				transition: all 0.15s ease-in-out;
			}
		}

		> .content {
			font-family: 'Open Sans', sans-serif;
			letter-spacing: 0.05em;
			position: relative;
			z-index: 2;
		}
	}

	&:hover {

		> .icon > i {
			background-color: #ea2e2b;
		}

		> .content-area {

			> .back-icon {
				opacity: 0.20;
				i {
					color: #ea2e2b;
				}
			}
		}
	}

}

.glass-icon {
	
	> i {
		position: relative;
		width: 78px;
		height: 78px;
		background-color: #000;
		color: #FFF;
		font-size: 25px;
		border-radius: 25px;
		transform: rotate(-45deg);
		background-image: url(../img/iconalt.svg);
		background-position: center center;
		background-size: 100% 100%;
		transition: all 0.15s ease-in-out;

		&:before {
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}