.section-header {
	position: relative;
	text-align: center;
	margin-bottom: 100px;
	padding: 0 15px;

	> .heading-effect {
		position: relative;
		display: inline-block;
		height: 125px;
		width: 175px;
		margin: 0 auto;
		margin-bottom: 20px;
		border-bottom: 2px dashed rgba(0,0,0,.20);

		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: 0px;
			border-right: 2px dashed rgba(0,0,0,.20);
			bottom: 0px;
			z-index: 21;
		}
		&.outside {
			height: 205px;

			&:before {
				height: 230px;
			}
		}
	}

	> .sub-heading {
		margin-top: 0;
		margin-bottom: 20px;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 1em;
		line-height: 1em;
		letter-spacing: 0.1em;
	}
	> .heading {
		font-size: 2.428em;
		line-height: 1em;
		letter-spacing: 0.1em;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;

		> span {
			font-weight: 300;
		}
	}

	> .description {
		margin-top: 50px;
		font-size: 1.142em;
		line-height: 2em;
		letter-spacing: 0.020em
	} 
}

.transparent-section {
	.section-header {
		> .heading-effect {
			border-bottom-color:rgba(255,255,255,.35);

			&:before {
				border-right-color:rgba(255,255,255,.35);
			}
		}
	}
}