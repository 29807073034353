@media (max-width: 1024px) {
	// Features Circles
	.circle-iconic-box {
		> .box-item {
			width: 200px;

		}

		&.left-side {
			> .box-item:nth-of-type(1) {
				top: -20px;
				left: 100px;
			}

			> .box-item:nth-of-type(2) {
				top: 100px;
				left: 70px;
			}

			> .box-item:nth-of-type(3) {
				top: 220px;
				left: 110px;
			}
		}

		&.right-side {
			> .box-item:nth-of-type(1) {
				top: -50px;
				left: -20px;
			}

			> .box-item:nth-of-type(2) {
				top: 80px;
				left: 0px;
			}

			> .box-item:nth-of-type(3) {
				top: 200px;
				left: -20px;
			}

			> .box-item:nth-of-type(4) {
				top: 325px;
				left: -90px;
			}
		}
	}

	.features-circle {
		position: relative;

		.middle-circle {
			width: 250px;
			height: 250px;

			> .icon {

				&.size-xxl {
					width: 100px;
					height: 100px;

					> i {
						font-size: 26px;
					}
				}

				&.size-m {
					width: 50px;
					height: 50px;

					> i {
						font-size: 14px;
					}
				}

				&.size-l {
					width: 75px;
					height: 75px;

					> i {
						font-size: 18px;
					}
				}

				&.size-xl {
					width: 85px;
					height: 85px;

					> i {
						font-size: 20px;
					}
				}

				&:nth-of-type(1) {
					top: -40px;
					left: 0px;

					> span {
						width: 40px;
						height: 11px;
						top: 20px;
						left: -50px;
					}
				}

				&:nth-of-type(2) {
					top: 75px;
					left: -25px;

					> span {
						width: 20px;
						height: 11px;
						top: 20px;
						left: -30px;
					}
				}

				&:nth-of-type(3) {
					top: 150px;
					left: -20px;

					> span {
						width: 40px;
						height: 28px;
						top: 50px;
						left: -40px;
					}
				}

				&:nth-of-type(4) {
					top: -30px;
					right: 50px;

					> span {
						width: 120px;
						height: 33px;
						background-position-x: -30px;
						top: -10px;
						left: 70px;
					}
				}
				&:nth-of-type(5) {
					top: 40px;
					right: -20px;

					> span {
						width: 40px;
						height: 11px;
						background-position-x: -5px;
						top: 35px;
						left: 80px;
					}
				}
				&:nth-of-type(6) {
					top: 160px;
					right: -5px;

					> span {
						width: 45px;
						height: 11px;
						top: 40px;
						left: 80px;
					}
				}
				&:nth-of-type(7) {
					top: 200px;
					right: 90px;

					> span {
						width: 86px;
						height: 28px;
						top: 110px;
						left: 75px;
					}
				}
			}
		}
	}

	// Team Members
	.team-peoples-carousel {
		.team-peoples {
			.people {
				> .people-profile {
					> .name-job {
						> .name {
							&:before {
								font-size: 1.7em;
							}
						}
					}
				}
			}
		}
	}

	// Portfolios
	.masonry-grid {

		> .grid-sizer {
			width: 33.33333333333333%;
		}

		> .grid-item {
			height: 250px;

			&.height2 {
				height: 500px;
			}
		}
	}
}
@media (max-width: 992px ) {
	#header
	#logo {
		float: none;
	}
	#top-menu {
		float: none;
		position: inherit;
		padding: 0;

		ul.menu-items {
			margin-top: 35px;
			margin-right: 0;


			> li {
				display: block;
				border-bottom: 1px solid #E6E6E6;
				&:last-child {
					border-bottom: none;
				}

				> a {
					display: block;
				}
			}
		}

		.nav-trigger {
			top: 45px;
			right: 40px;
		}
	}

	#top-social {
		float: none;
		position: absolute;
		top: 35px;
		right: 155px;
	}

	.site-header.scrolled {
		#top-social {
			top: 10px;
		}
		#top-menu .nav-trigger {
			top: 15px;
		}
	}

	.transparent-menu .site-header {
		padding-top: 15px;
		#top-social {
			float: none;
			position: absolute;
			top: 10px;
			right: 155px;
		}
		#top-menu .nav-trigger {
			top: 15px;
			right: 40px;
		}
	}
}
@media (max-width: 768px) {
	body,
	.transparent-menu {
		padding: 0;
	}
	// Side Nav
	.side-menu,
	.footer-blank,
	#top-social {
		display: none;
	}
	#back-to-top {
		bottom: 0;
		right: 0;
	}

	// Features Circles
	.circle-iconic-box {

		&.left-side {
			> .box-item:nth-of-type(1) {
				top: -20px;
				left: 0px;
			}

			> .box-item:nth-of-type(2) {
				top: 100px;
				left: 20px;
			}

			> .box-item:nth-of-type(3) {
				top: 220px;
				left: 0px;
			}
		}

		&.right-side {
			> .box-item:nth-of-type(1) {
				top: -50px;
				left: -20px;
			}

			> .box-item:nth-of-type(2) {
				top: 80px;
				left: 0px;
			}

			> .box-item:nth-of-type(3) {
				top: 200px;
				left: -20px;
			}

			> .box-item:nth-of-type(4) {
				top: 325px;
				left: -30px;
			}
		}
	}
	.features-circle {
		position: relative;

		.middle-circle {

			> .icon {

				&:nth-of-type(4) {
					top: -30px;					

					> span {
						width: 70px;
						background-position-x: -75px;
						top: 0px;
					}
				}
				&:nth-of-type(5) {

					> span {
						background-position-x: -15px;
						
					}
				}
				&:nth-of-type(6) {

					> span {
						width: 30px;
						background-position-x: -20px;
					}
				}
			}
		}
	}


	// Portfolios
	.masonry-grid {

		> .grid-sizer {
			width: 49.9%;
		}

		> .grid-item {
			width: 49.9%;
			height: 300px;

			&.width2 {
				width: 49.9%;
			}
			&.width3 {
				width: 49.9%;
			}
			&.width4 {
				width: 49.9%;
			}
			&.width5 {
				width: 49.9%;
			}
			&.height2 {
				height: 300px;
			}
		}
	}

	// Process Flow
	.process-flow-nav {
		> li {
			> a {
				> i {
					width: 75px;
					height: 75px;
					border-radius: 25px;
					font-size: 1.8em;
				}
			}
		}
	}

	// Pricing Table
	.pricing-table {
		margin: 0 50px 50px;
	}

	.main-slider {
		.slide-item {
			.slide-caption {
				.slide-mini-title {
					margin-bottom: 20px;					
				}
				.slider-huge-title {
					font-size: 4em;
					margin-bottom: 20px;
				}
				.slide-tag {
					margin-bottom: 20px;
				}
			}
		}

		.triangle {
			display: none;
		}
	}

	.special-testimonial {
		padding: 95px 55px;
		width: 90%;
		border-top: 10px solid #FFF;
		border-left: 10px solid #FFF;

		&:before {
			content: '';
			position: absolute;
			right: 0;
			bottom: 140px;
			width: 10px;
			height: 100%;
			background-color: #fff;
		}

		&:after {
			content: '';
			position: absolute;
			right: 140px;
			bottom: 0;
			height: 10px;
			width: 100%;
			background-color: #fff;
		}

		> .more {
			width: 100px;
			height: 100px;
			border-width: 0 0 100px 100px;

			> i {
				top: 45px;
				left: -50px;
				font-size: 40px;
			}
		}

		> .title {
			font-size: 2em;
			margin-bottom: 40px;
		}

		> .content {
			font-size: 1em;
			margin-bottom: 20px;
		}
	}

}

@media (max-width: 480px) {
	
	// Portfolios
	.masonry-grid {

		> .grid-sizer {
			width: 100%;
		}

		> .grid-item {
			width: 100%;
			height: 300px;

			&.width2 {
				width: 100%;
			}
			&.width3 {
				width: 100%;
			}
			&.width4 {
				width: 100%;
			}
			&.width5 {
				width: 100%;
			}
			&.height2 {
				height: 300px;
			}
		}
	}

	// Clients Log
	.clients-logo {
		.logo-item {
			margin-bottom: 25px;
			> a {
				border: 1px solid #e5e5e5 !important;
			}
		}
	}
}