.contact-form {
	input,
	textarea {
		width: 100%;
		border: 1px solid #e4e4e4;
		padding: 15px 30px;
		margin-bottom: 25px;
		transition: all 0.15s ease-in-out;
	textarea {
		margin-bottom: 50px;
	}

		&:focus {
			outline-color: transparent;
			border-color: #ea2e2b;
		}
	}

	.dt-ajax-response {
		margin-top: 50px;
		padding: 5px 10px;
		border: 1px solid $red;
		text-align: center;
		display: none;
	}
}