.blog-container {
	background-color: #f2f4f6;
	padding-top: 100px;
	padding-bottom: 150px;
}

.blog-posts {
	
	article.post {
		margin-bottom: 60px;
		border-bottom: 1px dashed #c7c7c7;

		.entry-featured {
			margin-bottom: 40px;

			> img {
				max-width: 100%;
				height: auto;
			}

			&.qoute {
				blockquote {
					position: relative;
					padding: 100px 80px;
					font-size: 1.142em;
					line-height: 1.75em;
					font-weight: 400;
					letter-spacing: 0.050em;
					font-style: italic;
					
					&:before,
					&:after {
						position: absolute;
						color: #ea2e2b;
						font-size: 30px;
						font-family: FontAwesome;
						font-style: normal;
					}

					&:before {
						content: "\f10d";
						top: 80px;
						left: 50px;
					}
					&:after {
						content: "\f10e";
						bottom: 80px;
						right: 50px;
					}
				}
			}

			&.carousel {
				.item {
					img {
						max-width: 100%;
						height: auto;
					}
				}
				.dt-carousel-control {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: #FFF;
					font-size: 40px;
					line-height: 0.75em;
					text-decoration: none;

					&:hover {
						text-decoration: none;
						background-color: rgba(#ea2e2b,0.5);
					}

					&.left {
						left: 50px;
					}

					&.right {
						right: 50px;
					}
				}
			}

			&.video {
				.mejs-video {
					max-width: 100%;

					video {
						max-width: 100%;
					}
				}
			}
		}
		.entry-inside {
			padding: 0 50px;

			> .entry-title {
				font-size: 1.428em;
				line-height: 1.5em;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.060em;
				margin-top: 0;
				margin-bottom: 30px;

				> a {
					color: #000;

					&:hover {
						color: #ea2e2b;
					}
				}
			}

			> .entry-excerpt {
				margin-bottom: 40px;
				letter-spacing: 0.020em;
				font-family: 'Open Sans', sans-serif;
			}

			> .entry-meta {
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					color: #999999;
					margin-right: 25px;

					> i {
						margin-right: 10px;
					}
					> a {
						color: #999999;

						&:hover {
							color: #ea2e2b;
						}
					}
				}
			}
		}

		.entry-readmore {
			font-size: 4em;
			line-height: 0.3em;
			color: #000;
			
			> i {
				line-height: 1em;
			}
			&:hover {
				color: #ea2e2b;
			}
		}

		&:before,
		&:after {
			content: "";
			display: table;
		}
		&:after {
			clear: both;
		}
	}
}