#banner {
	position: relative;
	overflow: hidden;

	// &.fullscreen {
	// 	height: 100vh;
	// }
}

.main-slider {
	.slide-item {
		.slide-caption {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			color: #FFF;

			.slide-mini-title {
				font-size: 1.285em;
				margin: 0;
				line-height: 1em;
				margin-bottom: 35px;
				font-weight: 300;
			}
			.slider-huge-title {
				font-size: 5.714em;
				margin: 0;
				line-height: 1em;
				margin-bottom: 35px;
				font-weight: 100;

				> span {
					color: #ea2e2b;
					font-weight: 700;
				}
			}
			.slide-tag {
				position: relative;
				font-weight: 600;
				margin-bottom: 35px;

				span {
					display: inline-block;
					position: relative;
					padding: 0 5px;
				}

				span:first-child:before,
				span:last-child:before {
					content: "";
					position: absolute;
					width: 150px;
					height: 1px;
					background-color: #FFF;
					right: 100%;
					margin-right: 20px;
					top: 50%;
				}
				span:last-child:before {
					right: inherit;
					margin-right: inherit;
					left: 100%;
					margin-left: 20px;
				}
			}
			.slide-description {
				font-weight: 600;
			}

			.slide-action {
				> a {
					color: #FFF;
				}
			}
		}
	}

	.slide-button {
		position: absolute;
		z-index: 25;
		top: 50%;
		margin-top: -20px;
		color: #aaaaaa;
		font-size: 40px;
		opacity: 0;
		transition: all 0.2s ease-in-out;

		&:hover {
			cursor: pointer;
		}
	}
	.slide-button.prev {
		left: -60px;
	}
	.slide-button.next {
		right: -60px;
	}

	&:hover {

		.slide-button.prev {
			opacity: 1;
			left: 60px;
		}
		.slide-button.next {
			opacity: 1;
			right: 60px;
		}
	}
}