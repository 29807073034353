.special-testimonial {
	position: relative;
	overflow: hidden;
	padding: 95px 55px;
	text-align: center;
	width: 740px;
	margin: 0 auto;
	border-top: 15px solid #FFF;
	border-left: 15px solid #FFF;

	&:before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 190px;
		width: 15px;
		height: 100%;
		background-color: #fff;
	}

	&:after {
		content: '';
		position: absolute;
		right: 190px;
		bottom: 0;
		height: 15px;
		width: 100%;
		background-color: #fff;
	}

	> .more {
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		color: #FFF;
		width: 150px;
		height: 150px;
		border-style: solid;
		border-width: 0 0 150px 150px;
		border-color: transparent transparent $red transparent;

		> i {
			position: absolute;
			top: 85px;
			left: -65px;
			font-size: 50px;
			color: #FFF;
			z-index: 55;
			transform: rotate(45deg);
		}
	}

	> .title {
		position: relative;
		font-size: 3.428em;
		letter-spacing: 0.020em;
		font-weight: 100;
		margin-top: 0;
		margin-bottom: 65px;

		> span {
			font-weight: 700;
			color: #ea2e2b;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: -20px;
			left: 50%;			
			width: 30%;
			height: 1px;
			background-color: #FFF;
			transform: translateX(-50%);
		}
	}

	> .content {
		font-size: 1.142em;
		font-weight: 400;
		font-style: italic;
		margin-bottom: 40px;
	}

	> .name {
		font-size: 1.285em;
		letter-spacing: 0.100em;
	}
}