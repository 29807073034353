.side-menu {
	position: fixed;
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
	z-index: 500;

	> ul {
		margin: 0;
		padding: 0;

		> li {
			list-style: none;
			margin-bottom: 30px;

			> a {
				display: inline-block;
				font-size: 1.8em;
				line-height: 0.8em;
				padding: 3px 0;
				color: #000;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					height: 0px;
					width: 2px;
					top: 0;
					left: -10px;
					background-color: #ea2e2b;
					transition: all 0.15s ease-in-out;
				}

				&:hover {
					color: #ea2e2b;
					
					&:before {
						height: 100%;
					}					
				}
				&:hover,
				&:focus {
					text-decoration: none;
					background-color: transparent;
				}
			}

			&.active {
				a {
					color: #ea2e2b;

					&:before {
						height: 100%;
					}
				}
			}
		}
	}
}