.dark-layout {
	background-color: #000;
	color: lighten(#000, 75%);

	input, textarea {
		background-color: #000;
		border-color: lighten(#000, 10%);
	}

	.site-header {
		background-color: #000;

		&.scrolled {
			background-color: #000;
			border-bottom-color: lighten(#000, 10%);
		}
		#logo > a > img {
			display: none;

			&.transparent-logo {
				display: block;
			}
		}
		
		.social-icons > li > a {
			color: lighten(#000, 75%);

			&:hover {
				color: $red;
			}
		}

		#top-menu {
			.menu-items > li > a {
				color: lighten(#000, 75%);

				&:hover {
					color: $red;
				}
			}
			.nav-trigger > .icon-bar > span {
				border-bottom-color: lighten(#000, 75%);
			}
			.nav-trigger > .text {
				color: #FFF;
			}
		}
	}

	&.transparent-menu {
		.site-header {
			background-color: transparent;

			&.scrolled {
				background-color: #000;
				border-bottom-color: lighten(#000, 10%);
			}

			.social-icons > li > a {
				color: lighten(#000, 75%);

				&:hover {
					color: $red;
				}
			}

			#top-menu {
				.menu-items > li > a {
					color: lighten(#000, 75%);

					&:hover {
						color: $red;
					}
				}
				.nav-trigger > .icon-bar > span {
					border-bottom-color: lighten(#000, 75%);
				}
				.nav-trigger > .text {
					color: #FFF;
				}
			}

			#logo > a > img {
				display: none;

				&.transparent-logo {
					display: block;
				}
			}
		}
	}

	.side-menu > ul > {
		li {
			> a {
				color: lighten(#000, 75%);

				&:hover {
					color: $red;
				}
			}

			&.active a {
				color: $red;
			}
		}
	}

	.glass-icon > i {
		background-color: lighten(#000, 25%);
	}

	.features-circle .middle-circle {
		border-color: lighten(#000, 75%);

		> .icon {
			background-color: lighten(#000, 25%);
			border-color: lighten(#000, 75%);

			&:nth-of-type(1) > span {
				background-image: url(../img/d-left-1-gray.png);
			}
			&:nth-of-type(2) > span {
				background-image: url(../img/d-left-2-gray.png);
			}
			&:nth-of-type(3) > span {
				background-image: url(../img/d-left-3-gray.png);
			}
			&:nth-of-type(4) > span {
				background-image: url(../img/d-right-1-gray.png);
			}
			&:nth-of-type(5) > span {
				background-image: url(../img/d-right-2-gray.png);
			}
			&:nth-of-type(6) > span {
				background-image: url(../img/d-right-3-gray.png);
			}
			&:nth-of-type(7) > span {
				background-image: url(../img/d-right-4-gray.png);
			}
		}
	}
	// Triangle
	.triangle {
		> span {
			color: #000;
		}
	}
	.footer-blank {
		background-color: #000;
		border-top-color: lighten(#000, 10%);		
	}
	.team-peoples-carousel .team-peoples .people > .people-profile > .name-job > .name:before {
		color: #FFF;
	}
	.pricing-table {
		background-color: #000;
		
		&.active {
			box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.25);
		}
	}
	.clients-logo .logo-item > a {
		border-right: 1px solid lighten(#000, 10%);
		border-bottom: 1px solid lighten(#000, 10%);
	}
	.section-header > .heading-effect {
		border-bottom: 2px dashed rgba(255, 255, 255, 0.2);

		&:before {
			border-right: 2px dashed rgba(255, 255, 255, 0.2);
		}
	}
}