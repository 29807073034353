.comments-area {
	.comments-title {
		font-size: 1.142em;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 70px;
	}
}
.comment-list {
	padding-left: 0;

	li.comment {
		list-style: none;
		margin-bottom: 50px;
		border-bottom: 1px dashed #c7c7c7;

		.comment-body {
			padding-bottom: 45px;

			> .avatar {
				width: 70px;
				height: 70px;
				border-radius: 50%;
				float: left;
			}
			.comment-meta {
				overflow: hidden;
				margin-bottom: 20px;

				> .comment-author {
					float: left;

					> .fn {
						font-weight: 700;
						text-transform: uppercase;
						> a {
							color: #000;

							&:hover {
								color: #ea2e2b;
							}
						}
					}
				}

				> .comment-metadata {
					float: right;

					.comment-date {
						color: #999999;

						&:hover {
							color: #ea2e2b;
						}
					}

					> span {
						padding: 0 5px;
						font-weight: bold;
					}

					.comment-reply-link {
						color: #000;

						&:hover {
							color: #ea2e2b;
						}
					}
				}

				&:before,
				&:after {
					content: "";
					display: table;
				}
				&:after {
					clear: both;
				}
			}

			.comment-content {
				padding-left: 100px;
			}

			&:before,
			&:after {
				content: "";
				display: table;
			}
			&:after {
				clear: both;
			}
		}

		> .children {
			.comment {
				border-bottom: none;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}