.comment-respond {

	.comment-reply-title {
		font-size: 1.142em;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 70px;
	}
	.comment-form {

		input,
		textarea {
			display: block;
			width: 100%;

			&[type="submit"] {
				display: table;
				width: auto;
				margin: 0 auto;
				background-color: #ea2e2b;
				color: #FFF;
				border-color: #ea2e2b;
				font-weight: 400;
				padding: 12px 30px;

				&:hover {
					background-color: transparent;
					color: #ea2e2b;
				}
			}
		}
		textarea {
			margin-bottom: 30px;
		}
		.input-fields {
			margin-bottom: 30px;
			margin-left: -15px;
			margin-right: -15px;

			.field-half {
				width: 50%;
				float: left;
				padding: 0 15px;
			}
		}
	}
}