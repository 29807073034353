.widget_categories {

	> ul {
		padding-left: 0;

		> li {
			list-style: none;

			> a {
				display: block;
				border-bottom: 1px solid #dcdcdc;
				padding: 15px 0;
				font-size: 1.142em;
				letter-spacing: 0.050em;
				color: #000;

				&:hover {
					color: #ea2e2b;
					text-decoration: none;
				}
			}
			
			&:first-child {
				> a {
					padding-top: 0;
				}
			}

			&:last-child {
				> a {
					border-bottom: none;
					padding-bottom: 0;
				}
			}
		}
	}
}