.filter-btns {
	text-align: center;
	margin-bottom: 50px;

	> button {
		border: 0;
		background-color: transparent;
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.1em;

		&.active, &:hover {
			color: #ea2e2b;
		}

		&:active, &:focus {
			outline-color: transparent;
		}
	}
}

.masonry-grid {
	max-width: 1800px;
	margin: 0 auto;

	> .grid-sizer {
		width: 20%;
	}
	> .grid-item {
		float: left;
		height: 390px;
		padding: 5px;
		width: 20%;

		> .content {
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;

			> img {
				width: 100%;
				height: auto;
			}
			
			.item-img {
				width: 100%;
				height: 100%;
				background-size: cover;
				transition: all 0.15s ease-in-out;
			}

			> .overlay-content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#ea2e2b, 0.65);
				opacity: 0;
				transition: all 0.15s ease-in-out;
				
				.info {
					color: #FFF;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					text-align: center;

					> .title {
						font-size: 1.285em;
						font-weight: 400;
						margin: 0;
						margin-bottom: 15px;
						text-transform: uppercase;
						transform: translateY(-150%);
						opacity: 0;
						transition: all 0.20s ease-in-out;
						transition-delay: 0.20s;
					}

					> .categories {
						font-weight: 400;
						transform: translateY(-150%);
						opacity: 0;
						transition: all 0.20s ease-in-out;
						transition-delay: 0.30s;
					}
				}

				> .action {
					position: absolute;
					bottom: 35px;
					right: 35px;

					> a {
						display: inline-block;
						width: 40px;
						height: 40px;
						background: #FFF;
						color: #000;
						border-radius: 50%;
						position: relative;
						margin: 5px;
						opacity: 0;
						transform: translateY(50px);
						transition: all 0.20s ease-in-out;
						transition-delay: 0.40s;

						&:last-child {
							transition-delay: 0.45s;
						}

						> i {
							position: absolute;
							font-size: 20px;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);
						}
					}
				}

			}

			&:hover {
				> .item-img {
					transform: scale(1.05) rotate(2deg);
				}
				> .overlay-content {
					opacity: 1;

					.info {
						> .title,
						> .categories {
							opacity: 1;
							transform: translateY(0%);
						}
					}

					.action {
						a {
							opacity: 1;
							transform: translateY(0px);
						}
					}
				}
			}
		}

		&.width2 {
			width: 40%;
		}
		&.width3 {
			width: 26.666666667%;
		}
		&.width4 {
			width: 33.333333333%;
		}
		&.width5 {
			width: 46.666666667%;
		}
		&.height2 {
			height: 780px;
		}

	}
}