.widget_search {
	> form {
		position: relative;

		input.search-field {
			width: 100%;
			border: 0px;
			border-bottom: 1px solid #ea2e2b;
			background-color: transparent;
			font-weight: 400;
			color: #ea2e2b;
			padding: 5px 0;

			&:active,
			&:focus {
				outline-color: transparent;
			}
		}

		button.search-submit {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background-color: transparent;
			border-color: transparent;
		}
	}
}