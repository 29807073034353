.widget_newsletter {
	> form {
		> input {
			width: 100%;
			border: 1px solid #e4e4e4;
			padding: 10px 20px;
			margin-bottom: 25px;
			transition: all 0.15s ease-in-out;

			&:focus {
				border-color: #ea2e2b;
			}
		}

		> button {
			border: 1px solid #ea2e2b;
			background-color: #ea2e2b;
			color: #FFF;
			font-size: 0.857em;
			line-height: 1em;
			font-weight: 400;
			padding: 10px 40px;
			text-transform: uppercase;
			transition: all 0.15s ease-in-out;

			&:hover {
				background-color: transparent;
				color: #000;
			}
		}
	}
}